import ApiHandler from "../apiHandler";
import Fetch from "../fetch";

const END_POINTS = {
	prefix: 'Languages',
	texts: 'texts',
};

class LanguagesApiHandler extends ApiHandler {
	async getLanguages(filter) {
		return Fetch.get(END_POINTS.prefix, filter);
	}

	async getLanguageTextsForProject(id, filter) {
		return Fetch.get(`${END_POINTS.prefix}/${id}/${END_POINTS.texts}`, filter);
	}
}

const languagesApiHandler = new LanguagesApiHandler();
export default languagesApiHandler;