import StoreBase from '../storeBase';
import { observable, action, makeObservable } from 'mobx';
import currenciesApiHandler from '../../core/services/apiService/apiHandlers/currenciesApiHandler';

class CurrenciesStore extends StoreBase {
	currencies = this.toState([]);
	currencyId = this.toState({});
	setCurrencies(currencies) {
		this.currencies = currencies;
	}

	constructor() {
		super();
		makeObservable(this, {
			currencies: observable,
			setCurrencies: action
		});
	}

	async getCurrencies(filter) {
		this.setCurrencies(this.toState(this.currencies.data, true));
		const res = await currenciesApiHandler.getCurrencies(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.currencies, isLoading: false };
		this.setCurrencies(result);
	}
}

export default new CurrenciesStore();