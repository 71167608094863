import { toJS } from 'mobx';

/**
 * It takes a MobX observable and converts it to a plain JavaScript object
 * @param data - The data you want to log.
 * @param [text] - The text to be displayed in the console.
 */
const mobxLog = (data, text = '') => {
    console.log(toJS(data), text);
};

export default mobxLog;
