export const DEFAULT_SELECTOR_VALUE = '12';


export const SELECTOR_DATA = [
    DEFAULT_SELECTOR_VALUE, '50', '100', '500'
];


export const DEFAULT_SKIP_TAKE = {
    skip: 0,
    take: DEFAULT_SELECTOR_VALUE
};