import Item from './item';
import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { feedbackDefaultTake } from './utils';
import useTranslation from 'hooks/useTranslation';
import { Typography } from 'view/components/shared';
import T from 'core/translations/translations.json';
import './index.scss';

const { BaseHeading, BaseLink } = Typography;

const Feedbacks = ({ state, getFeedbacks }) => {
	const translate = useTranslation();
	const count = useRef(feedbackDefaultTake);
	const { data: { filteredCount, items: feedbacks }, isLoading } = state;

	const loadMoreFeedbacksData = () => {
		count.current = count.current + feedbackDefaultTake;
		getFeedbacks(count.current);
	};

	if(!feedbacks.length) {
		return null;
	}

	return (
		<div className="landing_page_feedbacks_container">
			<BaseHeading level={3}>
				{translate(T.FEEDBACKS)}
			</BaseHeading>

			<div className="feedbacks_item_content">
				{feedbacks.map((item) => <Item key={item.id} {...item}/>)}
			</div>

			<div className="see_more_container">
				<BaseLink
					size="base"
					fontWeight="bold"
					onClick={loadMoreFeedbacksData}
					className="course_detail_link_color"
					disabled={count.current >= filteredCount}
				>
					{isLoading ? `${translate(T.LOADING)}...` : translate(T.SEE_MORE)}
				</BaseLink>
			</div>
		</div>
	);
};

export default observer(Feedbacks);