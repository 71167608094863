import React from 'react';
import { Upload } from 'antd';
import useTranslation from 'hooks/useTranslation';
import { UploadOutlined } from '@ant-design/icons';
import T from 'core/translations/translations.json';
import { Button, Typography } from 'view/components/shared';

const { BaseHeading } = Typography;

const UploadFile = ({ title, accept, setFileData, disabled }) => {
	const translate = useTranslation();

	const handleChange = (info) => {
		if(info.file.status === 'uploading') {
			return null;
		}

		setFileData({
			blob: info.file,
			name: info.file.name
		});
	};

	const beforeUpload = () => false;
	return (
		<div>
			<BaseHeading
				level={4}
				fontWeight="regular"
			>
				{title}
			</BaseHeading>
			<Upload
				name="file"
				maxCount={1}
				accept={accept}
				howUploadList={false}
				onChange={handleChange}
				beforeUpload={beforeUpload}
			>
				<Button icon={<UploadOutlined/>}>{translate(T.CLICK_TO_UPLOAD)}</Button>
			</Upload>
		</div>
	);
};

export default UploadFile;