import Fetch from '../fetch';

const END_POINTS = {
	prefix: 'feedbacks',
	public: 'public',
	courses: 'courses',
	instructors: 'instructors'
};

class FeedbacksApiHandler {
	async getCourseFeedbacks({ id, ...filter }) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.courses}/${id}`, filter);
	}

	async addFeedback({ id, data = {} }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.courses}/${id}`, data);
	}

	async getInstructorFeedbacks({ id, ...filter }) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.instructors}/${id}`, filter);
	}

	async addFeedbackForInstructor({ id, data }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.instructors}/${id}`, data);
	}

	async addFeedbackForCourse({ id, data }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.courses}/${id}`, data);
	}
}

const feedbacksApiHandler = new FeedbacksApiHandler();

export default feedbacksApiHandler;