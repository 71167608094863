export const BEARER = 'Bearer';
export const INCLUDE_CREDENTIALS = 'same-origin';

export const DATE_FORMATS = {
	M: 'mm',
	H: 'HH',
	DD: 'DD',
	H_M: 'HH:mm',
	TIME: 'HH:mm:ss',
	MONTH_YEAR: 'YYYY/MM',
	TABLE_SHORT: 'DD/MM/YYYY',
	POINT_SHORT: 'DD.MM.YYYY',
	INPUT_SHORT: 'YYYY-MM-DD',
	SLASH_SHORT: 'YYYY/MM/DD',
	INPUT_LONG: 'YYYY-MM-DD HH:mm',
	TABLE_LONG: 'DD/MM/YYYY HH:mm',
	POINT_LONG: 'DD/MM/YYYY HH:mm',
	POINT_LONG_TIME: 'DD.MM.YYYY HH:mm'
};
