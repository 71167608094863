import { useContext, useEffect, useMemo } from 'react';
import Skeleton from './skeleton';
import { observer } from 'mobx-react-lite';
import MDEditor from "@uiw/react-md-editor";
import { LanguageContext } from 'context/language';
import { EmptyWrapper } from 'view/components/shared';
import landingPageStore from 'stateManagement/stores/landingPageStore';
import './index.scss';

const ContentManagementSectionConfig = ({ sectionType }) => {
	const { language } = useContext(LanguageContext);
	const {
		landingPageData: { data: { sections } },
		sectionsContent: { data: sectionsContent, isLoading }
	} = landingPageStore;

	useEffect(() => {
		if(sections.length) {
			const findSection = sections.find((item) => item.type === sectionType);
			if(findSection) {
				landingPageStore.getSections({
					type: sectionType,
					id: findSection.id
				});
			}
		}

	}, [ language, sections ]);

	const pageContent = useMemo(() => {
		const content = sectionsContent[sectionType];
		return content ? content.pageContent : '';
	}, [ sectionsContent ]);

	return (
		<div className="md_editor_container">
			{
				isLoading ? <Skeleton/> : (
					<EmptyWrapper isEmpty={!pageContent}>
						<div data-color-mode="light">
							<MDEditor.Markdown
								linkTarget="_blank"
								source={pageContent}
							/>
						</div>
					</EmptyWrapper>
				)
			}
		</div>
	);
};

export default observer(ContentManagementSectionConfig);