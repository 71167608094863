import { Divider, Badge, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { ROUTE_CONSTANTS } from 'routing/router';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import { useHistory, useLocation } from 'react-router-dom';
import chatChannelsStore from 'stateManagement/stores/chatChannelsStore';
import { Avatar, ShowElement, Typography } from 'view/components/shared';
import { hubConnectionTypes } from 'core/services/signalrService/constant';
import './index.scss';
import mobxLog from '../../../../../../../../core/helpers/mobxLogHelper';

const { BaseLink, BaseBodyText } = Typography;

const OverlayContent = ({ chatEvents }) => {
	const history = useHistory();
	const translate = useTranslation();
	const { pathname } = useLocation();
	const newMessages = chatEvents[hubConnectionTypes.MESSAGE_RECEIVED];
	const chatGroups = chatEvents[hubConnectionTypes.GROUP_CHAT_CREATED];
	const updatedChatChannels = chatEvents[hubConnectionTypes.CHAT_CHANNEL_STATE_UPDATED];
	const handleSetConnectedChat = (id) => {
		if(pathname !== `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.CHAT}`) {
			history.push({
				pathname: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.CHAT}`
			});
		}
		chatChannelsStore.changeConnectedChat({ id });
	};

	return (
		<div className="chat_content">
			<ShowElement isShow={newMessages.length}>
				<>
					<Divider plain className="chat_event_overlay_divider">
						{translate(T.MESSAGES_EVENTS)}
					</Divider>
					{
						newMessages.map((item) => {
							return (
								<div className="chat_content_info" key={item.id}>
									<Space onClick={() => handleSetConnectedChat(item.chatChannel.id)}>
										<Badge count={item.count}>
											<Avatar src={item.sender.imagePath}/>
										</Badge>
										<BaseLink
											text={item.sender.name}
										/>
										<ShowElement isShow={item.chatChannel.name}>
											<BaseBodyText text={`${item.chatChannel.name}`}/>
										</ShowElement>
									</Space>
								</div>
							);
						})
					}
				</>
			</ShowElement>

			<ShowElement isShow={chatGroups.length}>
				<>
					<Divider plain className="chat_event_overlay_divider">
						{translate(T.GROUP_CHAT_CREATED)}
					</Divider>
					{
						chatGroups.map((item) => {
							return (
								<div className="chat_content_info" key={item.id}>
									<Space onClick={() => handleSetConnectedChat(item.id)}>
										<Avatar src={item.creator.imagePath}/>
										<BaseLink
											text={item.creator.name}
										/>
										<ShowElement isShow={item.name}>
											<BaseBodyText text={`${item.name}`}/>
										</ShowElement>
									</Space>
								</div>
							);
						})
					}
				</>
			</ShowElement>

			<ShowElement isShow={updatedChatChannels.length}>
				<>
					<Divider plain className="chat_event_overlay_divider">
						{translate(T.CHAT_CHANNEL_STATE_UPDATED)}
					</Divider>
					{
						updatedChatChannels.map((item) => {
							return (
								<div className="chat_content_info" key={item.id}/>
							);
						})
					}
				</>
			</ShowElement>
		</div>
	);
};

export default observer(OverlayContent);