/**
 * It takes a certificate object and returns a new object with the same data, but in a different format
 * @returns An object with the following properties:
 * 	certificateId: The id of the certificate
 * 	certificateType: An object with the following properties:
 * 		certificateTypeId: The id of the certificate type
 * 		name: The name of the certificate type
 * 		description: The description of the certificate type
 * 		institutionName: The name of the institution that
 */
export const transformCertificateDataModel = item => {
	const { certificate, issuedDate, validationUrl, expirationDate, certificateNumber } = item;
	return ({
		certificateId: certificate.id,
		certificateType: {
			certificateTypeId: certificate.id,
			name: certificate.name,
			description: certificate.description,
			institutionName: certificate.institutionName,
/**
 * It takes an array of objects and returns an object with the same data
 */
			logo: certificate.logoUrl
		},
		issuedDate,
		expirationDate,
		certificateNumber,
		validationUrl
	});
};


export const transformTranslationsDataModel = profileTexts => {
	const translationsModel = {};
	profileTexts?.forEach(translation => {
	    const { languageId, texts } = translation;
	    translationsModel[languageId] = {
	        ...texts
	    };
	});
	return translationsModel;
};


/**
 * It takes a skill object and returns a new object with the same properties, but with a different shape
 * @returns An object with the properties label, value, and experienceLevel.
 */
export const transformSkills = skill => {
	return ({
		label: skill.name,
		value: skill.skillId,
		experienceLevel: skill.experienceLevel
	});
};
