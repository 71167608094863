import React from 'react';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import { FormItem, ImgIcon } from 'view/components/shared';
import './index.scss';

const ButtonInsideInput = ({
							   type,
							   name,
							   icon,
							   value,
							   onChange,
							   placeholder
						   }) => {
	const translate = useTranslation();
	return (
		<FormItem
			name={name}
			rules={[ { type, message: translate(T.NOT_VALID_EMAIL) } ]}
		>
			<div className="button_inside_input_content">
				<input
					type="text"
					value={value}
					onChange={onChange}
					placeholder={translate(placeholder)}
				/>

				<FormItem shouldUpdate noStyle>
					{(form) => {
						return (
							<button
								disabled={
									!form.isFieldsTouched(true) || !form.getFieldValue()?.email ||
									form.getFieldsError().filter(({ errors }) => errors.length)
										.length > 0
								}
							>
								<ImgIcon src={icon}/>
							</button>
						);
					}}
				</FormItem>
			</div>
		</FormItem>
	);
};

export default ButtonInsideInput;