import { Suspense } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import Footer from 'view/pages/landing/footer';
import { ROUTE_CONSTANTS } from 'routing/router';
import { ShowElement } from 'view/components/shared';
import { LandingHeader, Loading, RouterOutlet } from 'view/components/global';

const LandingPage = () => {
	const location = useLocation();

	return (
		<Layout className="main-container">
			<div className="site-layout">
				<LandingHeader/>
				<div className="landing_page_container">
					<Suspense fallback={<Loading/>}>
						<RouterOutlet/>
					</Suspense>
				</div>

				<ShowElement isShow={location.pathname !== ROUTE_CONSTANTS.EMPTY}>
					<Footer/>
				</ShowElement>
			</div>
		</Layout>
	);
};

export default LandingPage;