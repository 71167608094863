import React, { useMemo } from 'react';
import { DatePicker } from 'antd';
import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import formatDate from 'core/helpers/dateHelper';
import useTranslation from 'hooks/useTranslation';
import createQuery from 'core/helpers/createQuery';
import { DATE_FORMATS } from 'core/constants/globalConstants';
import { DEFAULT_SKIP_TAKE } from 'core/constants/tableConstants';
import moment from 'moment';
import './index.scss';

const { RangePicker } = DatePicker;

const RangeDatePicker = ({
							 fromKey,
							 toKey,
							 fromText,
							 toText,
							 width = 250
						 }) => {
	const { queryObject } = useQuery();
	const translate = useTranslation();
	const history = useHistory();

	const handleRangeDatePickerChange = (startKey, endKey, values) => {
		if(values) {
			const [ startDate, endDate ] = values;
			let endValue = null;
			let startValue = null;
			if(startDate && endDate) {
				endValue = formatDate(new Date(endDate), DATE_FORMATS.INPUT_SHORT);
				startValue = formatDate(new Date(startDate), DATE_FORMATS.INPUT_SHORT);
				history.push(createQuery({
					...queryObject,
					...DEFAULT_SKIP_TAKE,
					[startKey]: startValue,
					[endKey]: endValue
				}));
			}
		} else {
			delete queryObject[startKey];
			delete queryObject[endKey];
			history.push(createQuery({
				...queryObject,
				...DEFAULT_SKIP_TAKE
			}));
		}
	};

	const rangePickerData = useMemo(() => {
		if(queryObject[fromKey] && queryObject[toKey]) {
			return ([
				moment(queryObject[fromKey]),
				moment(queryObject[toKey])
			]);
		}
		return null;
	}, [ queryObject ]);


	return (
		<RangePicker
			style={{ width }}
			value={rangePickerData}
			className="range_date_picker"
			placeholder={[ translate(fromText), translate(toText) ]}
			onChange={value => handleRangeDatePickerChange(fromKey, toKey, value)}
		/>

	);
};

export default RangeDatePicker;