import React from 'react';
import { LanguageContext } from 'context/language';
import { useStickyState } from 'hooks/useStickyState';

const WithLanguage = ({ children }) => {
	//Todo Sometime
	const [ language, setLanguage ] = useStickyState('language', {
		name: 'Español',
		id: 2,
		flagUrl: 'flagicons/es-ar.svg'
	});
	return (
		<LanguageContext.Provider value={{ language, setLanguage }}>
			{children}
		</LanguageContext.Provider>
	);
};

export default WithLanguage;