import { action, makeObservable, observable } from 'mobx';
import StoreBase from '../storeBase';
import usersApiHandler from 'core/services/apiService/apiHandlers/usersApiHandler';
import {
	transformCertificateDataModel,
	transformSkills,
	transformTranslationsDataModel
} from 'core/helpers/editProfile';
import { downloadStreamFile } from 'core/helpers/downloadStreamFile';


const instructorProfileInitialState = {
	phoneNumber: '',
	title: null,
	linkedInProfileUrl: '',
	canPublishLinkedInProfile: null,
	skills: [],
	certificates: [],
	translations: {},
	cvPath: '',
	userId: '',
	portfolioImages:[]
};

const studentProfileInitialState = {
	bio: '',
	birthDate: '',
	education: '',
	educationLevel: null,
	interests: [],
	languages: [],
	linkedInUrl: '',
	userId: ''
};

const studentResponseDataValidation = (profileData) => {
	const responseDataMode = {};
	for (let i in profileData) {
		if(profileData[i] === null || profileData[i] === 0) {
			responseDataMode[i] = studentProfileInitialState[i];
		} else {
			responseDataMode[i] = profileData[i];
		}
	}
	return {
		...responseDataMode
	};
};

const resumeFormDataModel = (fileData) => {
	const formData = new FormData();
	formData.append('Resume', fileData.blob, fileData.name);
	return formData;
};

const editUserFormDataModel = (data) => {
	const { fileData, firstName, lastName, headLine, phoneNumber } = data;
	const formData = new FormData();
	formData.append('lastName', lastName);
	formData.append('firstName', firstName);
	formData.append('phoneNumber', phoneNumber);
	formData.append('headLine', headLine);
	fileData.blob && formData.append('image', fileData.blob, fileData.name);
	return formData;
};


const updateUserAvatar = (data) => {
	const { fileData, phoneNumber } = data;
	const formData = new FormData();
	fileData.blob && formData.append('image', fileData.blob, fileData.name);
	formData.append('phoneNumber', phoneNumber);
	return formData;
};

class UsersStore extends StoreBase {
	meData = this.toState({});
	profileMeData = this.toState({});
	studentProfile = this.toState(studentProfileInitialState);
	instructorProfile = this.toState(instructorProfileInitialState);

	constructor() {
		super();
		makeObservable(this, {
			studentProfile: observable,
			instructorProfile: observable,
			meData: observable,
			profileMeData: observable,
			setMeData: action,
			setProfileMeData: action,
			setInstructorProfile: action,
			setStudentProfile: action
		});
	}

	setInstructorProfile(instructorProfile) {
		this.instructorProfile = instructorProfile;
	}

	setStudentProfile(studentProfile) {
		this.studentProfile = studentProfile;
	}

	setMeData(meData) {
		this.meData = meData;
	};

	setProfileMeData(profileMeData) {
		this.profileMeData = profileMeData;
	}

	transformInstructorProfileData(profileData) {
		const { data: { phoneNumber, preferredLanguageId } } = this.meData;
		const {
			title,
			linkedInUrl,
			canPublishLinkedInProfile,
			skills,
			certificates,
			cvPath,
			userId,
			profileTexts,
			portfolioImages
		} = profileData;

		return ({
			phoneNumber,
			title,
			linkedInProfileUrl: linkedInUrl,
			canPublishLinkedInProfile,
			preferredLanguageId,
			skills: skills?.map(transformSkills) || [],
			certificates: certificates?.map(transformCertificateDataModel) || [],
			translations: transformTranslationsDataModel(profileTexts) || {},
			cvPath,
			userId,
			portfolioImages
		});
	}


	async setActivateKey(activationKey) {
		const res = await usersApiHandler.setActivateKey(activationKey);
		return this.isOk(res);
	}

	async getProfile() {
		const res = await usersApiHandler.getProfile();
		console.log(res);
	}

	async getMe() {
		this.setMeData(this.toState(this.meData.data, true));
		const res = await usersApiHandler.getMe();
		const result = res?.result ? this.toState(res.result) : { ...this.meData, isLoading: false };
		this.setMeData(result);
	}

	async getProfileMeData() {
		this.setProfileMeData(this.toState(this.profileMeData.data, true));
		const res = await usersApiHandler.getMe();
		const result = res?.result ? this.toState(res.result) : { ...this.profileMeData, isLoading: false };
		this.setProfileMeData(result);
	}

	async addInstructorProfile(formData) {
		const res = await usersApiHandler.addInstructorProfile(formData);
		return this.isOk(res);
	}

	async getInstructorProfile() {
		this.setInstructorProfile(this.toState(this.instructorProfile.data, true));
		const res = await usersApiHandler.getUserProfile();
		const result = res?.result ? this.toState(this.transformInstructorProfileData(res.result)) : {
			...this.instructorProfile,
			isLoading: false
		};
		this.setInstructorProfile(result);
	};

	async getStudentProfile() {
		this.setStudentProfile(this.toState(this.studentProfile.data, true));
		const res = await usersApiHandler.getUserProfile();
		const result = res?.result ? this.toState(studentResponseDataValidation(res.result)) : {
			...this.studentProfile,
			isLoading: false
		};
		this.setStudentProfile(result);
	}

	async editInstructorResume(fileData) {
		const formData = resumeFormDataModel(fileData);
		const res = await usersApiHandler.editInstructorResume(formData);
		return this.isOk(res);
	};

	async editInstructorProfile(data) {
		const res = await usersApiHandler.editInstructorProfile(data);
		return this.isOk(res);
	}

	async editStudentProfile(data) {
		const res = await usersApiHandler.editStudentProfile(data);
		return this.isOk(res);
	}

	async getInstructorProfileResume({ cvPath, fileName }) {
		const resp = await usersApiHandler.getInstructorProfileResume();
		downloadStreamFile(resp, fileName);
	}

	async updateUserAvatar(data) {
		const formData = updateUserAvatar(data);
		const res = await usersApiHandler.updateUserAvatar(formData);
		return this.isOk(res);
	};


	async editUser(data) {
		const formData = editUserFormDataModel(data);
		const res = await usersApiHandler.editUser(formData);
		return this.isOk(res);
	}

	async removePortfolioImage({id,type}){
		const res=await usersApiHandler.removePortfolioImage({id,type});
		return this.isOk(res);
	}
}

const userStore = new UsersStore();
export default userStore;