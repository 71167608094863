import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import useDidUpdateEffect from "../../../../hooks/useDidUpdateEffect";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useHistory } from 'react-router-dom';
import useQuery from '../../../../hooks/useQuery';
import './index.scss';

const { Search: SearchComponent } = Input;
const defaultEventKey = "Enter";

const Search = ({
					value,
					setValue,
					onChange,
					placeholder,
					debounceTime,
					width = 250,
					eventKey = defaultEventKey
				}) => {
	const [ searchTerm, setSearchTerm ] = useState('');
	const { queryObject } = useQuery();
	const history = useHistory();

	useEffect(() => {
		setSearchTerm(value || '');
	}, [ value ]);

	const debouncedSearchTerm = useDebounce(searchTerm, debounceTime, false);

	useDidUpdateEffect(() => {
		onChange(debouncedSearchTerm);
	}, [ debouncedSearchTerm ]);

	const handleChange = event => {
		const { value } = event.target;
		setValue(value);

	};

	const handleKeyUp = (event) => {
		if(event.key === eventKey) {
			onChange(searchTerm);
		}
	};

	return (
		<div className="ant_des_search_container">
			<SearchComponent
				value={value}
				style={{ width }}
				onChange={handleChange}
				placeholder={placeholder}
				className="ant_des_search"
			/>
		</div>

	);
};

export default Search;