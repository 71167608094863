import moment from 'moment';

/**
 * It takes a dateTime string and a format string and returns a dateTime string in the local time zone.
 * @param dateTime - The date and time you want to convert.
 * @param format - The format of the dateTime string.
 * @returns The dateTimeZone function is returning the local date and time in the format specified.
 */
export const dateTimeZone = (dateTime, format) => {
	const testDateUtc = moment.utc(dateTime);
	const localDate = moment(testDateUtc).local();
	return localDate.format(format)
};
