import { IMG_URL } from 'core/constants/urls';

/**
 * "If the image fails to load, replace it with the error image."
 *
 * The `onError` event handler is called when the image fails to load. The `e` parameter is the event object. The
 * `e.target` property is the image element. The `e.target.src` property is the image source
 * @returns A React component that renders an image.
 */
const Img = ({
				 src,
				 errorImgSrc = '',
				 baseUrl = true,
				 alt = '',
				 className = ''
			 }) => {
	const imgSrc = baseUrl ? `${IMG_URL}/${src}` : src;

	return (
		<img
			alt={alt}
			src={imgSrc}
			className={className}
			onError={e => e.target.src = errorImgSrc}
		/>
	);
};

export default Img;