import React from 'react';
import ReactDOM from 'react-dom';

const PageTitle = ({ children }) => {
    const container = document.getElementById('header-title-container');

    if (container) {
        return (
            ReactDOM.createPortal(
                <>
                    {children}
                </>,
                container
            )
        )
    }

    return <div />
};

export default PageTitle;