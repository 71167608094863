import React from 'react';
import { Form, Switch, Typography } from 'antd';
import T from '../../../../core/translations/translations.json';
import useTranslation from '../../../../hooks/useTranslation';
import FormItem from '../formItem';


const Switcher = ({ label, name, disabled = false, onChange, valuePropName = 'checked' }) => {
	return (
		<FormItem
			name={name}
			label={label}
			valuePropName={valuePropName}
		>
			<Switch
				name={name}
				disabled={disabled}
				onChange={onChange}
			/>
		</FormItem>
	);
};

export default Switcher;