import React from 'react';
import { RadioCollapseContext } from './context';
import { Collapse as AntCollapse, Radio, Space } from 'antd';
import useTranslation from '../../../../hooks/useTranslation';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import './index.scss';

const { Panel } = AntCollapse;

const RadioCollapse = ({ children, defaultActiveKey, setActiveCollapsePanel }) => {
	const translation = useTranslation();
	const handleRadioChange = (e) => {
		const { value } = e.target;
		setActiveCollapsePanel(value);
	};

	return (
		<Radio.Group value={defaultActiveKey} onChange={handleRadioChange} className="full_width">
			<RadioCollapseContext.Provider
				value={{
					translation,
					defaultActiveKey,
					setActiveCollapsePanel
				}}>
				<Space direction="vertical" className="full_width">
					{children}
				</Space>
			</RadioCollapseContext.Provider>
		</Radio.Group>
	);
};

RadioCollapse.Collapse = ({ title, nameKey, children }) => {

	const expandIcon = ({ isActive }) => {
		const Icon = isActive ? CaretUpOutlined : CaretDownOutlined;
		return <Icon style={{ color: '#597EF7' }}/>;
	};

	return (
		<RadioCollapseContext.Consumer>
			{
				({ translation, defaultActiveKey, setActiveCollapsePanel }) => (
					<div className={`main_collapse_container ${defaultActiveKey === nameKey ? 'active' : ''}`}>
						<AntCollapse
							collapsible="header"
							expandIcon={expandIcon}
							expandIconPosition="right"
							activeKey={defaultActiveKey}
							onChange={() => {
								console.log();
								setActiveCollapsePanel(nameKey)
							}}
						>
							<Panel
								key={nameKey}
								header={
									<div className="panel_header_content">
										<Radio value={nameKey}>{translation(title)}</Radio>
									</div>
								}
							>
								<div>
									{children}
								</div>
							</Panel>
						</AntCollapse>
					</div>
				)
			}
		</RadioCollapseContext.Consumer>
	);
};

export default RadioCollapse;