import StoreBase from '../storeBase';
import { storageStateKey } from 'core/constants/utils';
import { action, makeObservable, observable } from 'mobx';
import { sendMessageFormDataMode } from './helpers/chatChannels';
import LocalStorageHelper from 'core/helpers/localStorageHelper';
import { downloadStreamFile } from 'core/helpers/downloadStreamFile';
import { transformChatMessageForList } from './helpers/chatChannelsStore';
import { hubConnectionTypes } from 'core/services/signalrService/constant';
import { chatChannelReceipientState, chatChannelState } from '../../core/constants/enums';
import chatChannelsApiHandler from 'core/services/apiService/apiHandlers/chatChannelsApiHandler';

const chatEventsInitialState = {
	[hubConnectionTypes.MESSAGE_RECEIVED]: [],
	[hubConnectionTypes.GROUP_CHAT_CREATED]: [],
	[hubConnectionTypes.CHAT_CHANNEL_STATE_UPDATED]: [],
	count: 0
};

class ChatChannelsStore extends StoreBase {
	users = this.toState([]);
	chatEvents = this.toState(chatEventsInitialState);

	connectedChat = this.toState(LocalStorageHelper.getItem(storageStateKey) || {});
	chatChannelsRecipients = this.toState([]);
	chatChannelsRecipientsForAvatars = this.toState([]);
	messages = this.toState({ filteredCount: 0, messages: {} });
	channelsGroups = this.toState({ items: [], filteredCount: 0 });
	hasMessage = false;

	constructor() {
		super();
		makeObservable(this, {
			setChannelsGroups: action,
			setChatChannelsRecipients: action,
			setMessages: action,
			setUser: action,
			setChatChannelsRecipientsForAvatars: action,
			channelsGroups: observable,
			connectedChat: observable,
			hasMessage: observable,
			messages: observable,
			setHasMessage: action,
			users: observable,
			chatEvents: observable,
			setChatEvents: action,
			setConnectedChat: action,
			chatChannelsRecipientsForAvatars: observable,
			chatChannelsRecipients: observable
		});
	}

	setChannelsGroups(channelsGroups) {
		this.channelsGroups = channelsGroups;
	}

	setHasMessage(hasMessage) {
		this.hasMessage = hasMessage;
	}

	setConnectedChat(connectedChat) {
		this.connectedChat = connectedChat;
	}

	setUser(users) {
		this.users = users;
	}

	setMessages(messages) {
		this.messages = messages;
	}

	setChatEvents(chatEvents) {
		this.chatEvents = chatEvents;
	}

	setChatChannelsRecipientsForAvatars(chatChannelsRecipientsForAvatars) {
		this.chatChannelsRecipientsForAvatars = chatChannelsRecipientsForAvatars;
	}

	setChatChannelsRecipients(chatChannelsRecipients) {
		this.chatChannelsRecipients = chatChannelsRecipients;
	}

	async createChannelsGroups(data) {
		const res = await chatChannelsApiHandler.createChannelsGroups(data);
		return this.isOk(res);
	};

	async createChannels(data) {
		const res = await chatChannelsApiHandler.createChannels(data);
		return this.isOk(res);
	};

	async getUsers() {
		this.setUser(this.toState(this.users.data, true));
		const res = await chatChannelsApiHandler.getUsers();
		const result = res?.result ? this.toState(res.result) : { ...this.data, isLoading: false };
		this.setUser(result);
	}

	async getChannelsGroups(filter) {
		this.setChannelsGroups(this.toState(this.channelsGroups.data, true));
		const res = await chatChannelsApiHandler.getChannelsGroups(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.channelsGroups, isLoading: false };
		this.setChannelsGroups(result);
	};

	async updateChatGroupsUsers(data) {
		const res = await chatChannelsApiHandler.updateChatGroupsUsers(data);
		return this.isOk(res);
	}

	async getChatChannelsRecipients(id) {
		this.setChatChannelsRecipients(this.toState(this.chatChannelsRecipients.data, true));
		const res = await chatChannelsApiHandler.getChatChannelsRecipients(id);
		const result = res?.result ? this.toState(res.result) : { ...this.chatChannelsRecipients, isLoading: false };
		this.setChatChannelsRecipients(result);
	}

	async getChatChannelsRecipientsForAvatars(id) {
		this.setChatChannelsRecipientsForAvatars(this.toState(this.chatChannelsRecipientsForAvatars.data, true));
		const res = await chatChannelsApiHandler.getChatChannelsRecipients(id);
		const result = res?.result ? this.toState(res.result) : {
			...this.chatChannelsRecipientsForAvatars,
			isLoading: false
		};
		this.setChatChannelsRecipientsForAvatars(result);
	}

	async getMessageAttachment({ name, id }) {
		const resp = await chatChannelsApiHandler.getMessageAttachment(id);
		downloadStreamFile(resp, name);
	}

	async deleteChatMessage(data) {
		const res = await chatChannelsApiHandler.deleteChatMessage(data);
		return this.isOk(res);
	};

	async changeMemberState(data) {
		const res = await chatChannelsApiHandler.changeMemberState(data);
		return this.isOk(res);
	}

	async memberLeaveGroupChat(data) {
		const res = await chatChannelsApiHandler.memberLeaveGroupChat(data);
		if(data.state === chatChannelReceipientState.LEFT && data.id === this.connectedChat?.data?.id) {
			this.setMessages(this.toState({
				filteredCount: 0,
				messages: {}
			}, false));
			LocalStorageHelper.setItem(storageStateKey, {});
		}
		return this.isOk(res);
	}

	async senMessage({ id, data }) {
		const formData = sendMessageFormDataMode(data);
		const res = await chatChannelsApiHandler.senMessage({ formData, id });
		return this.isOk(res);
	};

	async changeChatState(data) {
		const res = await chatChannelsApiHandler.changeChatState(data);

		if(data.state === chatChannelState.CLOSED && data.id === this.connectedChat?.data?.id) {
			this.setMessages(this.toState({
				filteredCount: 0,
				messages: {}
			}, false));
			LocalStorageHelper.setItem(storageStateKey, {});
		}
		return this.isOk(res);
	}

	async getMessages(filter) {
		this.setMessages(this.toState(this.messages.data, true));
		const res = await chatChannelsApiHandler.getMessages(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.messages, isLoading: false };
		this.setMessages(transformChatMessageForList(result));
	}


	//------------------------> signalr action methods <------------------------

	//Signalr action helpers <------------------------

	/**
	 * It clears the connected chat data from local storage and sets the connected chat state to an empty object
	 * @param chatChannelId - The ID of the chat channel that was disconnected.
	 */
	clearConnectedChatData(chatChannelId) {
		if(this.connectedChat?.data?.id === chatChannelId) {
			this.setMessages(this.toState({
				filteredCount: 0,
				messages: {}
			}, false));
		}

		console.log('clearConnectedChatData >>>>>>');

		LocalStorageHelper.setItem(storageStateKey, {});
		this.setConnectedChat(this.toState({}, false));
	}

	clearDirectMessageCachet(chatChannelId) {
		if(this.connectedChat?.data?.id === chatChannelId) {
			this.setMessages(this.toState({
				filteredCount: 0,
				messages: {}
			}, false));
			LocalStorageHelper.setItem(storageStateKey, {});
			this.setConnectedChat(this.toState({}, false));
		}
	}

	//--------------------------------------------------------------------------

	addNewGroup(newGroupData) {
		if(newGroupData.id) {
			const { items, filteredCount } = this.channelsGroups.data;
			this.setChannelsGroups(this.toState({
				filteredCount: filteredCount + 1,
				items: [ newGroupData, ...items ]
			}, false));
		}
	}

	removeChatInGroups(chatChannelId) {
		const { items, filteredCount } = this.channelsGroups.data;
		this.setChannelsGroups(this.toState({
			filteredCount: filteredCount - 1,
			items: items.filter((item) => item.id !== chatChannelId)
		}));

		this.clearConnectedChatData(chatChannelId);
	}

	chatChannelStateUpdatedEvent(newEventData) {
		this.setChatEvents(this.toState({
			...this.chatEvents.data,
			[hubConnectionTypes.CHAT_CHANNEL_STATE_UPDATED]: [
				...this.chatEvents.data[hubConnectionTypes.CHAT_CHANNEL_STATE_UPDATED],
				newEventData
			],
			count: this.chatEvents.data.count + 1
		}));
	}

	removeChannelForGroups(data) {
		let { items, filteredCount } = this.channelsGroups.data;
		items = items.filter((item) => item.id !== data.id);
		this.setChannelsGroups(this.toState({
			items,
			filteredCount: filteredCount - 1
		}, false));

		this.clearConnectedChatData(data.id);
	}


	updateGroupChatCreatedEvent(newEventData) {
		this.setChatEvents(this.toState({
			...this.chatEvents.data,
			[hubConnectionTypes.GROUP_CHAT_CREATED]: [
				...this.chatEvents.data[hubConnectionTypes.GROUP_CHAT_CREATED],
				newEventData
			],
			count: this.chatEvents.data.count + 1
		}));
	}


	removeMessageInChat(chatMessageId) {
		const { messages, filteredCount } = this.messages.data;
		if(messages[chatMessageId]) {
			delete messages[chatMessageId];
			this.setHasMessage(this.toState({
				filteredCount: filteredCount - 1,
				messages
			}, false));
		}
	}

	updateMessageList(newMessageData) {
		if(this.connectedChat?.data?.id === newMessageData?.chatChannel?.id) {
			const { messages, filteredCount } = this.messages.data;
			this.setMessages(this.toState({
				filteredCount: filteredCount + 1,
				messages: {
					[newMessageData.id]: {
						...newMessageData
					},
					...messages
				}
			}, false));

			setTimeout(() => {
				this.setHasMessage(true);
			}, 100);
		}
	}

	updateNewMessageSignalrEvent(newEventData) {
		const receivedMessages = this.chatEvents.data[hubConnectionTypes.MESSAGE_RECEIVED];
		const findIndex = receivedMessages.findIndex((item) => item.chatChannel.id === newEventData.chatChannel.id);
		if(findIndex === -1) {
			receivedMessages.push({
				...newEventData,
				count: 1
			});
		}

		if(findIndex !== -1) {
			receivedMessages[findIndex] = {
				...receivedMessages[findIndex],
				count: receivedMessages[findIndex].count + 1
			};
		}

		this.setChatEvents(this.toState({
			...this.chatEvents.data,
			[hubConnectionTypes.MESSAGE_RECEIVED]: receivedMessages,
			count: this.chatEvents.data.count + 1
		}));
	}

	updateChatGroups(newGroups) {
		const { items, filteredCount } = this.channelsGroups.data;
		this.setChannelsGroups(this.toState({
			filteredCount: filteredCount + 1,
			items: [ ...items, newGroups ]
		}, false));
	}

	clearSignalrEventCount() {
		this.setChatEvents(this.toState({
			items: this.chatEvents.data.items,
			count: 0
		}, true));
	}

	clearSignalrEventData() {
		this.setChatEvents(this.toState(chatEventsInitialState));
	}

	changeConnectedChat(data) {
		LocalStorageHelper.setItem(storageStateKey, data);
		this.setConnectedChat(this.toState(data));
	}
}

export default new ChatChannelsStore();