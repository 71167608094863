import { useEffect, useState } from 'react';
import Typography from '../typography';
import { Rate as AndRate } from 'antd';
import ShowElement from '../showElement';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import './index.scss';

const { BaseBodyText } = Typography;

/**
 * It's a wrapper for the Ant Design Rate component that allows you to control the value of the rate component
 * @returns A React component
 */
const Rate = ({
				  onChange,
				  size = 14,
				  textType = 'secondary',
				  value: controlledValue,
				  disabled = true,
				  allowClear = false,
				  showRateNumber = true
			  }) => {
	const translate = useTranslation();
	const [ rateValue, setRateValue ] = useState(0);

	useEffect(() => {
		setRateValue(controlledValue);
	}, [ controlledValue ]);

	const handleRateChange = (value) => {
		onChange?.(value);
	};

	const rateToFixedValue = rateValue && +rateValue?.toFixed(1);

	if(rateValue === null) {
		return null;
	}

	return (
		<div className="base_rate_container">
			<AndRate
				// allowHalf
				disabled={disabled}
				allowClear={allowClear}
				value={rateToFixedValue}
				style={{ fontSize: size }}
				onChange={handleRateChange}
			/>

			<ShowElement isShow={showRateNumber}>
				<BaseBodyText size="small" fontWeight="medium" type={textType}>
					{rateToFixedValue} ({translate(T.RATING)})
				</BaseBodyText>
			</ShowElement>
		</div>
	);
};

export default Rate;