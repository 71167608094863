/**
 * @param {string} queryString
 * @returns {object} query object
 */
/**
 * It takes a query string and returns an object with the query string parameters as keys and their values as the
 * corresponding key values
 * @param queryString - The query string to parse.
 * @returns An object with the query string parameters as keys and values.
 */
const parseQuery = (queryString) => {
    const query = {};
    if (!queryString) return {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        if (query[decodeURIComponent(pair[0])]) {
            if (Array.isArray(query[decodeURIComponent(pair[0])])) {
                query[decodeURIComponent(pair[0])].push(decodeURIComponent(pair[1] || ''));
            } else {
                query[decodeURIComponent(pair[0])] = [
                    query[decodeURIComponent(pair[0])],
                    decodeURIComponent(pair[1] || '')
                ];
            }
        } else {
            query[decodeURIComponent(pair[0])] = (decodeURIComponent(pair[1])) ? (
                decodeURIComponent(pair[1])
            ) : decodeURIComponent(pair[1] || '');
        }
    }
    return query;
};

export default parseQuery;
