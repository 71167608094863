import React from 'react';
import useTranslation from 'hooks/useTranslation';
import { buttonSizes, buttonTypes, radiusTypes } from './utils';
import './index.scss';


const Button = ({
					fullWidth = false,
					type,
					size = 'medium',
					text,
					icon,
					radius,
					onClick,
					loading,
					disabled,
					children,
					className = ''

				}) => {
	const translate = useTranslation();

	const checkButtonStyle = buttonTypes.includes(type) ? type : buttonTypes[0];
	const checkButtonSize = buttonSizes.includes(size) ? size : buttonSizes[0];
	const checkButtonRadius = radiusTypes.includes(radius) ? radius : radiusTypes[0];

	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className={`
			lp_btn ${loading ? 'loading' : ''} 
			${fullWidth ? 'fullWidth' : ''}  
			${disabled ? 'disabled' : ''}
			${checkButtonStyle} ${checkButtonSize} ${checkButtonRadius} ${className}`}
		>

			<div className="lp_btn_content">
				{children ? children : (
					<>
						<span>{translate(text)}</span>
						{icon && <span className="lp_icon_content">{icon}</span>}
					</>
				)}
			</div>
		</button>
	);
};

export default Button;