import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { noop } from 'core/constants/utils';
import useTranslation from 'hooks/useTranslation';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import './index.scss';

/**
 * It's a wrapper around the Ant Design InputNumber component that allows for controlled and uncontrolled usage
 * @returns A number input component
 */

const NumberInput = ({
						 name,
						 readOnly,
						 placeholder,
						 onChange = noop,
						 width = '100%',
						 value: controlledValue,
						 ...restProps
					 }) => {
	const [ value, setValue ] = useState(controlledValue);
	const translate = useTranslation();

	useDidUpdateEffect(() => {
		setValue(controlledValue);
	}, [ controlledValue ]);


	const handleChange = (event) => {
		setValue(value);
		onChange(event);
	};

	return (
		<InputNumber
			name={name}
			style={{ width }}
			value={value ?? ''}
			readOnly={readOnly}
			onChange={handleChange}
			placeholder={translate(placeholder)}
			className={`${readOnly ? 'readonly_number_input' : ''} main_input_number`}
			{...restProps}
		/>
	);
};

export default NumberInput;