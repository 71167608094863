import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import useDidUpdateEffect from './useDidUpdateEffect';


export const useDebounce = (value, delay, clearTimeoutAfterPathChange) => {
    // State and setters for debounced value

    const [debouncedValue, setDebouncedValue] = useState(value);
    const timeoutId  = useRef(null);
    const { pathname } = useLocation();
    useEffect(() => {
        timeoutId.current = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            timeoutId.current && clearTimeout(timeoutId.current);
        }
    }, [value, delay]);
    useDidUpdateEffect(() => {
        clearTimeoutAfterPathChange && timeoutId.current && clearTimeout(timeoutId.current);
    }, [pathname]);
    return debouncedValue;
};