import { Link } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import './index.scss';

const Breadcrumb = ({ data, separator = '>' }) => {
	const translate = useTranslation();
	const breadcrumbItem = (title, path) => {
		const translatedTitle = translate(title);
		return (
			<AntBreadcrumb.Item className="breadcrumb-item" key={`${title}_${path}`}>
				{path ? <Link to={path}>{translatedTitle}</Link> : translatedTitle}
			</AntBreadcrumb.Item>
		);
	};
	return (
		<AntBreadcrumb separator={separator} className="breadcrumb-content">
			{
				Array.isArray(data) ?
					data.map(({ title, path }) => breadcrumbItem(title, path)) : breadcrumbItem(data.title, data.path)
			}
		</AntBreadcrumb>
	);
};

export default Breadcrumb;
