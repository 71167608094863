import StoreBase from '../storeBase';
import { action, makeObservable, observable } from 'mobx';
import { landingPageSectionType } from '../../core/constants/enums';
import landingPageApiHandler from 'core/services/apiService/apiHandlers/landingPageApiHandler';
import {dynamicPageContentInitialState} from './helpers/landingPage';

const transformLandingPageData = (data) => {
	let { sections, menuItems } = data;
	sections = sections.map(item => ({
		...item,
		name: landingPageSectionType[item.type] || ''
	}));

	return { sections, menuItems };
};

const includeSections = [
	landingPageSectionType.ABOUT,
	landingPageSectionType.CONTACT_US,
	landingPageSectionType.REQUEST_COURSE,
	landingPageSectionType.BECOME_INSTRUCTOR
];

class LandingPageStore extends StoreBase {
	sectionsContent = this.toState({});
	instructor = this.toState({});
	expertContactInfo=this.toState({});
	searchedCourses = this.toState({ filteredCount: 0, items: [] });
	coursesFilters = this.toState({});
	menuItems = this.toState({ filteredCount: 0, items: [] });
	courses = this.toState([]);
	// sectionsData = this.toState({});
	landingPageData = this.toState({ sections: [], menuItems: [] });
	instructors = this.toState({ items: [], filteredCount: 0 });
	coursePublicDetail = this.toState({});
	courseSchedules = this.toState([]);
	magicAction=this.toState({});
	magicReviewAction=this.toState({});
	dynamicPageContent=this.toState(dynamicPageContentInitialState);
	magicActonCourses=this.toState({courses:[]});
	magicClientInfo=this.toState({});
	skills=this.toState({items:[]});

	constructor() {
		super();
		makeObservable(this, {
			courses: observable,
			menuItems: observable,
			landingPageData: observable,
			coursesFilters: observable,
			sectionsContent: observable,
			instructor: observable,
			searchedCourses: observable,
			coursePublicDetail: observable,
			courseSchedules: observable,
			instructors: observable,
			skills:observable,
			setCourses: action,
			setMenuItems: action,
			setLandingPageData: action,
			setSectionsContent: action,
			setInstructors: action,
			setSkills:action,
			setCoursesFilters: action,
			setSearchedCourses: action,
			setCoursePublicDetail: action,
			setInstructor: action,			
			setMagicAction:action,
			magicAction:observable,
			setMagicReviewAction:action,
			magicReviewAction:observable,
			setDynamicPageContent: action,
			dynamicPageContent: observable,
			setMagicActonCourses:action,
			magicActonCourses: observable,
			setMagicClientInfo: action,
			magicClientInfo:observable,
			setExpertContactInfo: action,
			expertContactInfo: observable
		});
	}

	setMenuItems(menuItems) {
		this.menuItems = menuItems;
	};

	setSearchedCourses(searchedCourses) {
		this.searchedCourses = searchedCourses;
	}

	setDynamicPageContent(dynamicPageContent){
		this.dynamicPageContent=dynamicPageContent;
	}

	setLandingPageData(landingPageData) {
		this.landingPageData = landingPageData;
	}

	setSectionsContent(sectionsContent) {
		this.sectionsContent = sectionsContent;
	}

	setCourses(courses) {
		this.courses = courses;
	};

	setInstructor(instructor) {
		this.instructor = instructor;
	}

	setExpertContactInfo(expertContactInfo) {
		this.expertContactInfo = expertContactInfo;
	}

	setCourseSchedules(courseSchedules) {
		this.courseSchedules = courseSchedules;
	}

	setCoursePublicDetail(coursePublicDetail) {
		this.coursePublicDetail = coursePublicDetail;
	}

	setCoursesFilters(coursesFilters) {
		this.coursesFilters = coursesFilters;
	}

	setMagicClientInfo(data){
		this.magicClientInfo=data;
	}	
	
	setMagicActonCourses(data){
		this.magicActonCourses=data;
	}

	setInstructors(instructors) {
		this.instructors = instructors;
	}

	setSkills(skills){
		this.skills = skills;
	}

	setMagicAction(data){
		this.magicAction=data;
	}	
	setMagicReviewAction(data){
		this.magicReviewAction=data;
	}	

	async getMagicClientInfo({key, email, phone}){
		this.setMagicClientInfo(this.toState(this.magicClientInfo.data,true));
		const res=await landingPageApiHandler.getMagicSearchClient({actionKey:key,email,phone});
		const result=res?.result? this.toState(res.result) : {...this.magicClientInfo,isLoading:false};
		this.setMagicClientInfo(result);
	}

	async getMagicActonCourses(id){
		this.setMagicActonCourses(this.toState(this.magicActonCourses.data,true));
		const res=await landingPageApiHandler.getMagicActonCourses(id);
		const result=res?.result ? this.toState({courses:res.result.items}) : {...this.magicActonCourses, isLoading: false};
		this.setMagicActonCourses(result);
	}

	async getMagicAction(key){
		this.setMagicAction(this.toState(this.magicAction.data, true));
		const res= await landingPageApiHandler.getMagicActionResponse(key);
		const result = res?.result ? this.toState(res.result) : { ...this.magicAction, isLoading: false };
		this.setMagicAction(result);
	}

	async submitReviewMagicAction(data){		
		this.setMagicReviewAction(this.toState(this.magicReviewAction.data, true));
		const res= await landingPageApiHandler.processMagicAction(data);		
		const result = res?.result ? this.toState(res.result) : { ...this.magicReviewAction, isLoading: false };
		this.setMagicReviewAction(result);
		return res;
	}

	async subscribers(data) {
		const res = await landingPageApiHandler.subscribers(data);
		return this.isOk(res);
	}

	async unsubscribe(data) {
		const res = await landingPageApiHandler.unsubscribe(data);
		return this.isOk(res);
	}

	async sendMessage(data) {
		const res = await landingPageApiHandler.sendMessage(data);
		return this.isOk(res);
	}

	async scheduleRequests(data) {
		const res = await landingPageApiHandler.scheduleRequests(data);
		return this.isOk(res);
	}

	async getLandingPageData(filter) {
		this.setLandingPageData(this.toState(this.landingPageData.data, true));
		const res = await landingPageApiHandler.getLandingPageData(filter);
		const result = res?.result ? this.toState(transformLandingPageData(res.result)) : {
			...this.landingPageData,
			isLoading: false
		};
		this.setLandingPageData(result);
	}

	async getSections({ id, type }) {
		this.setSectionsContent(this.toState(this.sectionsContent.data, true));
		const res = await landingPageApiHandler.getSections(id);
		const result = res.result ? this.toState({
			...this.sectionsContent.data,
			[type]: includeSections.includes(type) ? res.result[0] ? res.result[0] : null : res.result.length ? res.result : null
		}) : { ...this.sectionsContent, isLoading: false };
		this.setSectionsContent(result);
	}

	async getCourses(languageId) {
		return await landingPageApiHandler.getCourses(languageId);
	}

	async getInstructors(filter) {
		this.setInstructors(this.toState(this.instructors.data, true));
		const res = await landingPageApiHandler.getInstructors(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.instructors, isLoading: false };
		this.setInstructors(result);
	}

	async getSkills(languageId){
		this.setSkills(this.toState(this.skills.data, true));
		const res=await landingPageApiHandler.getSkillsHavingInstructor(languageId);
		const result=res?.result ? this.toState(res.result) : { ...this.skills, isLoading: false};
		this.setSkills(result);
	}

	async getInstructor(instructorId) {
		this.setInstructor(this.toState(this.instructor.data, true));
		const res = await landingPageApiHandler.getInstructor(instructorId);
		const result = res?.result ? this.toState(res.result) : { ...this.instructor, isLoading: false };
		this.setInstructor(result);
	}

	async getExpertContactInfo(expertId){
		this.setExpertContactInfo(this.toState(this.expertContactInfo.data, true));
		const res= await landingPageApiHandler.getExpertContactInfo(expertId);
		const result=res?.result ? this.toState(res.result) : { ...this.expertContactInfo, isLoading: false};
		this.setExpertContactInfo(result)
	}

	async getCoursesFilters() {
		this.setCoursesFilters(this.toState(this.coursesFilters.data, true));
		const res = await landingPageApiHandler.getCoursesFilters();
		const result = res?.result ? this.toState(res.result) : { ...this.coursesFilters, isLoading: false };
		this.setCoursesFilters(result);
	}

	async getCourseSchedules(id) {
		this.setCourseSchedules(this.toState(this.courseSchedules.data, true));
		const res = await landingPageApiHandler.getCourseSchedules(id);
		const result = res?.result ? this.toState(res.result) : { ...this.courseSchedules, isLoading: false };
		this.setCourseSchedules(result);
	}

	async searchCourses(filter) {
		this.setSearchedCourses(this.toState(this.searchedCourses.data, true));
		const res = await landingPageApiHandler.searchCourses(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.searchedCourses, isLoading: false };
		this.setSearchedCourses(result);
	}

	async getPublicCourseDetail(courseId) {
		this.setCoursePublicDetail(this.toState(this.coursePublicDetail.data, true));
		const res = await landingPageApiHandler.getPublicCourseDetail(courseId);
		const result = res?.result ? this.toState(res.result) : { ...this.coursePublicDetail, isLoading: false };
		this.setCoursePublicDetail(result);
	}

	async getDynamicPageContent({languageId,path}){
		this.setDynamicPageContent(this.toState(this.dynamicPageContent.data,true));
		const res=await landingPageApiHandler.getDynamicPageContetnt({languageId,path});
		const result=res?.result ? this.toState(res.result) : 
		{data:dynamicPageContentInitialState, isLoading:false};
		this.setDynamicPageContent(result);
	}
}

const landingPageStore = new LandingPageStore();
export default landingPageStore;