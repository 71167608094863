import { action, makeObservable, observable } from 'mobx';
import StoreBase from '../storeBase';
import { transformLanguagesTextData } from './helpers/languages';
import languagesApiHandler from '../../core/services/apiService/apiHandlers/languagesApiHandler';

class LanguagesStore extends StoreBase {
	languages = this.toState({ filteredCount: 0, items: [] });
	languagesForProject = this.toState({});

	constructor() {
		super();
		makeObservable(this, {
			languages: observable,
			setLanguages: action,
			languagesForProject: observable,
			setLanguagesForProject: action
		});
	}

	setLanguages(languages) {
		this.languages = languages;
	}

	setLanguagesForProject(languagesForProject) {
		this.languagesForProject = languagesForProject;
	}

	async getLanguages(filter) {
		this.setLanguages(this.toState(this.languages.data, true));
		const res = await languagesApiHandler.getLanguages(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.languages, isLoading: false };
		this.setLanguages(result);
	}

	async getLanguageTextsForProject(id, filter) {
		this.setLanguagesForProject(this.toState(this.languagesForProject.data, true));
		const res = await languagesApiHandler.getLanguageTextsForProject(id, filter);
		const result = res?.result ? this.toState(transformLanguagesTextData(res.result)) : {
			...this.languagesForProject,
			isLoading: false
		};
		this.setLanguagesForProject(result);
	}

}


const languagesStore = new LanguagesStore();
export default languagesStore;
