import { DOMAIN_API } from '../../constants/urls';

export const storageStateKey = 'connectedChat';

export const signalrWithUrl = `${DOMAIN_API}/notificationHub`;

export const hubConnectionTypes = {
	MESSAGE_REMOVED: 'MessageRemoved',
	MESSAGE_RECEIVED: 'MessageReceived',
	GROUP_CHAT_CREATED: 'GroupChatCreated',
	INVITATION_TO_GROUP_CHAT: 'InvitationToGroupChat',
	CHAT_CHANNEL_STATE_UPDATED: 'ChatChannelStateUpdated',
	CHAT_CHANNEL_RECIPIENT_STATE_UPDATED: 'ChatChannelRecipientStateUpdated'
};