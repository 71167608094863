import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import authStore from '../../stateManagement/stores/authStore';
import { ROUTE_CONSTANTS } from '../router';

const IsRegisterLayer = ({ children }) => {
    const { isRegister } = authStore;
    return (
        <>
            {
                isRegister ? children : <Redirect to={ROUTE_CONSTANTS.EMPTY} />
            }
        </>
    );
};

export default observer(IsRegisterLayer);