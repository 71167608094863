import { useEffect, useState } from 'react';
import useQuery from 'hooks/useQuery';
import { Table as AntdTable } from 'antd';
import { useHistory } from 'react-router-dom';
import createQuery from 'core/helpers/createQuery';
import { SELECTOR_DATA } from 'core/constants/tableConstants';
import { tablePageCalc } from 'core/helpers/tablePaginationCalc';
import './index.scss';

/**
 * It takes a bunch of props, and returns a table
 * @returns A table component that is being rendered.
 */
const Table = ({
				   rowKey = "id",
				   rows,
				   loading,
				   columns,
				   rowsCount,
				   pageSize,
				   showQuickJumper = true,
				   showSizeChanger = true,
				   ...restProps
			   }) => {
	const { queryString, queryObject } = useQuery();
	const [ page, setPage ] = useState(tablePageCalc(queryObject));
	const history = useHistory();

	useEffect(() => {
		setPage(tablePageCalc(queryObject));
	}, [ queryString ]);

	const handleTableChange = (pagination) => {
		const { current, pageSize } = pagination;
		const skip = (current - 1) * pageSize;
		history.push(createQuery({
			...queryObject,
			skip,
			take: pageSize
		}));
	};

	return (
		<AntdTable
			rowKey={rowKey}
			columns={columns}
			dataSource={rows}
			loading={loading}
			className="table-container"
			onChange={handleTableChange}
			pagination={{
				current: page,
				showSizeChanger,
				total: rowsCount,
				showQuickJumper,
				pageSize: pageSize ? pageSize : queryObject.take,
				pageSizeOptions: SELECTOR_DATA
			}}
			{...restProps}
		/>
	);
};

export default Table;