import { lazy } from 'react';
import { GLOBAL_ROUTE_CONSTANTS } from 'routing/utils';
import defineGuards from 'routing/defineGuards/defineGuards';
import onlyInstructorRoute from './layers/onlyInstructorRoute';

const Schedule = lazy(() => import('view/pages/schedule'));
const Revenues = lazy(() => import('view/pages/financials/revenues'));
const Payments = lazy(() => import('view/pages/financials/payments'));
const AddCourseSetup = lazy(() => import('view/pages/courses/instructor/addCourseSetup'));
const EditCourseSetup = lazy(() => import('view/pages/courses/instructor/editCourseSetup'));

/* A way to define constants for routes. */
export const ROUTE_CONSTANTS = {
	SCHEDULE: '/schedule',
	REVENUES: '/revenues',
	PAYMENTS: '/payments',
	ADD_COURSES: '/add-courses',
	EDIT_COURSES: '/edit-courses'

};

export const instructorRoutes = [
	{
		path: `${GLOBAL_ROUTE_CONSTANTS.CABINET}${GLOBAL_ROUTE_CONSTANTS.COURSES}${ROUTE_CONSTANTS.ADD_COURSES}`,
		component: () => defineGuards([ onlyInstructorRoute ], AddCourseSetup),
		exact: true
	},
	{
		path: `${GLOBAL_ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.SCHEDULE}`,
		component: () => defineGuards([ onlyInstructorRoute ], Schedule),
		exact: true
	},
	{
		path: `${GLOBAL_ROUTE_CONSTANTS.CABINET}${GLOBAL_ROUTE_CONSTANTS.COURSES}${ROUTE_CONSTANTS.EDIT_COURSES}/:courseId`,
		component: () => defineGuards([ onlyInstructorRoute ], EditCourseSetup),
		exact: true
	},
	{
		path: `${GLOBAL_ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.REVENUES}`,
		component: () => defineGuards([ onlyInstructorRoute ], Revenues),
		exact: true
	},
	{
		path: `${GLOBAL_ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.PAYMENTS}`,
		component: () => defineGuards([ onlyInstructorRoute ], Payments),
		exact: true
	}
];
