import { useEffect, useRef, useState } from 'react';

export const useWindowScroll = () => {
	const prevScroll = window.pageYOffset;
	const ref = useRef(null);
	const [ scrolling, setScrolling ] = useState(0);

	useEffect(() => {
		window.onscroll = e => {
			const currentScrollPos = window.pageYOffset;
			setScrolling(e.target.documentElement.scrollTop);
			if(prevScroll >= currentScrollPos) {
				if(ref.current) {
					ref.current.style.top = '0px';
				}
			} else {
				if(ref.current) {
					ref.current.style.top = '-121px';
				}
			}
		};
	}, [ ref, prevScroll, scrolling ]);
	return {
		ref
	};
};

