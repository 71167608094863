import React from 'react';
import { Redirect } from 'react-router-dom';
import authStore from '../../stateManagement/stores/authStore';
import { observer } from 'mobx-react-lite';
import { ROUTE_CONSTANTS } from "../router";

const IsNotAuthLayer = ({ children }) => {
	const { isAuth } = authStore;
	return isAuth ? <Redirect to={ROUTE_CONSTANTS.EMPTY}/> : children;
};

export default observer(IsNotAuthLayer);

