import React from 'react';
import { Space, message, Button } from 'antd';
import { concatHashtag } from './helpers';
import { DOMAIN, IMG_URL, SSRDOMAIN, SSRQUERY } from 'core/constants/urls';
import { ROUTE_CONSTANTS } from 'routing/router';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, EmailIcon, EmailShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { CopyOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { copyTextToClipboard } from 'core/helpers/textCopty';
//import useTranslation from 'hooks/useTranslation';
//import T from 'core/translations/translations.json';


const Share = ({ tags, idForSharing, description, courseTitle, socialSharingImagePath }) => {
	//const translate = useTranslation();

	//const successMessage = () => message.success(translate(T.COPY_SUCCESS));

	const copyShareLink=async()=>{
		await copyTextToClipboard(`${SSRDOMAIN}${ROUTE_CONSTANTS.COURSE_DETAIL_SEO_FACEBOOK}/${idForSharing}?d=${SSRQUERY}`);
		//successMessage();
	};

	return (
		<div key={courseTitle}>
			<Helmet>
				<title>
					({courseTitle})
				</title>
				<meta
					name="description"
					content={description}
				/>
				<meta
					name="og:description"
					content={description}
				/>
				<meta
					name="og:title"
					content={courseTitle}
				/>
				<meta
					property="og:image"
					content={`${IMG_URL}/${socialSharingImagePath}`}
				/>
			</Helmet>
			
			<Space>
				<Button style={{fontSize:'130%'}} shape='circle'  type='primary' onClick={copyShareLink()} icon={<CopyOutlined style={{fontSize:'100%'}}/>}></Button>
				<EmailShareButton url={`${SSRDOMAIN}${ROUTE_CONSTANTS.COURSE_DETAIL_SEO_FACEBOOK}/${idForSharing}?d=${SSRQUERY}`}
					className="Demo__some-network__share-button">
					
					<EmailIcon size={32} round/>
				</EmailShareButton>
				<FacebookShareButton
					url={`${SSRDOMAIN}${ROUTE_CONSTANTS.COURSE_DETAIL_SEO_FACEBOOK}/${idForSharing}?d=${SSRQUERY}`}
					quote={courseTitle}
					hashtag={concatHashtag(tags)}
					description={description}
					className="Demo__some-network__share-button"
				>
					<FacebookIcon size={32} round/>
				</FacebookShareButton>

				<LinkedinShareButton
					quote={courseTitle}
					url={`${SSRDOMAIN}${ROUTE_CONSTANTS.COURSE_DETAIL_SEO}/${idForSharing}?d=${SSRQUERY}`}
					hashtag={concatHashtag(tags)}
					description={description}
					className="Demo__some-network__share-button"
				>
					<LinkedinIcon size={32} round/>
				</LinkedinShareButton>	
				<WhatsappShareButton url={`${SSRDOMAIN}${ROUTE_CONSTANTS.COURSE_DETAIL_SEO_FACEBOOK}/${idForSharing}?d=${SSRQUERY}`}
					className="Demo__some-network__share-button">
					
					<WhatsappIcon size={32} round/>
				</WhatsappShareButton>			
				
			</Space>
		</div>
	);
};

export default Share;