import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { GLOBAL_ROUTE_CONSTANTS } from 'routing/utils';
import authStore from 'stateManagement/stores/authStore';
import userStore from 'stateManagement/stores/usersStore';
import { isActiveStudentProfile } from 'core/helpers/profileRouteValidation';

const onlyStudentRoute = ({ children }) => {
	const { isAuth } = authStore;
	const { meData: { data: meData } } = userStore;

	return (
		<>
			{isAuth && isActiveStudentProfile(meData) ? children :
				<Redirect to={`${GLOBAL_ROUTE_CONSTANTS.CABINET}${GLOBAL_ROUTE_CONSTANTS.DASHBOARD}`}/>}
		</>
	);
};

export default observer(onlyStudentRoute);
