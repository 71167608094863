import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import authStore from '../../stateManagement/stores/authStore';
import { ROUTE_CONSTANTS } from '../router';
import userStore from '../../stateManagement/stores/usersStore';
import { isActiveUser } from '../../core/helpers/profileRouteValidation';

const IsAuthLayer = ({ children }) => {
	const { isAuth } = authStore;
	const { meData: { data: meData } } = userStore;

	return (
		<>
			{isAuth && isActiveUser(meData) ? children : <Redirect to={ROUTE_CONSTANTS.EMPTY}/>}
		</>
	);
};

export default observer(IsAuthLayer);

