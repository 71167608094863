const modules = {
	toolbar: [
		[ { 'header': [ 1, 2, 3, 4, 5, 6 ] } ],
		[ 'bold', 'italic', 'underline', 'strike', 'blockquote' ],
		[ { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' } ],
		[ 'link' ],
		[ 'clean' ],
		[ { 'color': [] }, { 'background': [] } ],          // dropdown with defaults from theme
		[ { 'font': [] } ],
		[ { 'align': [] } ],
		[ { 'size': [ 'small', false, 'large', 'huge' ] } ]  // custom dropdown
	]
};

const feedbackModules = {
	toolbar: false
};

const formats = [
	'header',
	'font',
	'bold', 'italic', 'underline', 'strike', 'blockquote',
	'list', 'bullet', 'indent',
	'link', 'image',
	'color', 'background',
	'font',
	'align',
	'size',
];
export const editorDefaultValue = '<p><br></p>';
export {
	modules, formats, feedbackModules
};