import { useEffect, useState } from 'react';
import App from './app';
import { history } from 'routing/history';
import { observer } from 'mobx-react-lite';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/global/loading';
import authStore from 'stateManagement/stores/authStore';
import userStore from 'stateManagement/stores/usersStore';
import WithLanguage from 'view/components/global/withLanguage';
import languagesStore from '../stateManagement/stores/languagesStore';
import './index.scss';
import '../global_class.scss';

const AppWrapper = () => {
	const [ isLoading, seIsLoading ] = useState(true);

	const { isAuth } = authStore;
	const { meData: { isLoading: meLoading } } = userStore;

	useEffect(() => {
		firstRefresh();
	}, [ meLoading ]);

	const firstRefresh = async () => {
		await authStore.init();
		seIsLoading(false);
	};

	useEffect(() => {
		languagesStore.getLanguages();
	}, []);

	useEffect(() => {
		if(isAuth) {
			userStore.getMe();
		}
	}, [ isAuth ]);

	return (
		<BrowserRouter history={history}>
			<WithLanguage>
				{isLoading ? <Loading/> : <App/>}
			</WithLanguage>
		</BrowserRouter>
	);
};

export default observer(AppWrapper);