import React from 'react';
import Content from './content';
import { Avatar, Dropdown, Space } from 'antd';
import './index.scss';

const AvatarMenu = ({
						email,
						fullName,
						imagePath
					}) => {
	let splitData = fullName.split(' ');
	const initials = splitData.shift().charAt(0) + splitData.pop().charAt(0).toUpperCase();

	return (
		<Dropdown
			overlay={
				<Content
					email={email}
					fullName={fullName}
					imagePath={imagePath}
				/>
			}
			placement="bottomRight"
		>
			<div className="user-profile">
				<Space>
					<Avatar
						className="auth_avatar"
						style={{ backgroundColor: '#2728E5', cursor: 'pointer' }}
						size="large"
					>
						{initials}
					</Avatar>
				</Space>
			</div>
		</Dropdown>
	);
};

export default AvatarMenu;