import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import parseQuery from '../core/helpers/parseQuery';
import createQuery from '../core/helpers/createQuery';
import { DEFAULT_SKIP_TAKE } from '../core/constants/tableConstants';

const useQuery = () => {
	const location = useLocation();

	const [ queryObject, setQueryObject ] = useState({
		...DEFAULT_SKIP_TAKE,
		...parseQuery(location.search)
	});

	useEffect(() => {
		setQueryObject({ ...DEFAULT_SKIP_TAKE, ...parseQuery(location.search) });
	}, [ location.search ]);

	return {
		queryObject,
		queryString: createQuery(queryObject)
	};

};

export default useQuery;
