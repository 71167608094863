import { userStatus, userTypes } from '../constants/enums';

export const isActiveUser = data => {
	return +data.status === userStatus.ACTIVE;
};

export const isDeclinedProfile = data => {
	return +data.status === userStatus.DECLINED;
};

export const isPendingReviewInstructor = data => {
	return data.status === userStatus.PENDING_REVIEW && data.type === userTypes.INSTRUCTOR;
};

export const isPendingProfileInstructor = data => {
	return data.status === userStatus.PENDING_PROFILE && data.type === userTypes.INSTRUCTOR;
};

export const isActiveInstructorProfile = data => {
	return +data.status === userStatus.ACTIVE && data.type === userTypes.INSTRUCTOR;
}

export const isActiveStudentProfile = data => {
	return +data.status === userStatus.ACTIVE && data.type === userTypes.STUDENT;
}