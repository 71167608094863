import useTranslation from 'hooks/useTranslation';
import { Button as AntButton, Tooltip } from 'antd';
import './index.scss';

const Button = ({
					style,
					opacity,
					text = '',
					title = '',
					size = "medium",
					type = "default",
					childrenClassName,
					children,
					tooltipTitle = "",
					placement = "top",
					...restProps
				}) => {
	const translate = useTranslation();

	return (
		<Tooltip placement={placement} title={translate(tooltipTitle)}>
			<span>
				<AntButton
					className={`${type === 'default' ? 'ant-btn-default' : ''} ${size} button-wrapper`}
					type={type}
					size={size}
					style={{ ...style }}
					{...restProps}
				>
					   <span className={`${childrenClassName} base_button_content`}>
							 {children || translate(text)}
					   </span>
				</AntButton>
			</span>
		</Tooltip>
	);
};

export default Button;