const socialData = [
	{ id: 1, name: 'email', href: 'mailto:support@serca.pro', icon: 'envelope' },	
	{ id: 2, name: 'whatsapp', href: 'https://wa.me/17077434151', icon: 'whatsapp' },
	{ id: 3, name: 'facebook', href: 'https://fb.com/sercaprof', icon: 'facebook-square' },
	{ id: 4, name: 'instagram', href: 'https://www.instagram.com/serca.pro.arg/', icon: 'instagram'},
	{ id: 5, name: 'youtube', href: 'https://www.youtube.com/@SercaPro', icon: 'youtube'}
];

export {
	socialData
};