import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS } from 'routing/router';
import T from 'core/translations/translations.json';
import { Button } from 'view/components/landingPage';
import { ShowElement } from 'view/components/shared';
import authStore from 'stateManagement/stores/authStore';
import AvatarMenu from 'view/components/global/avatarMenu';
import AvatarMenuWrapper from 'view/components/global/avatarMenuWrapper';
import LanguageDropDown from 'view/components/landingPage/languageDropDown';
import './index.scss';

const RightContent = () => {
	const history = useHistory();
	const { isAuth } = authStore;

	const handleLogin = () => history.push(ROUTE_CONSTANTS.LOGIN);

	return (
		<div className="navigation_right_content">
			<ShowElement isShow={!isAuth}>
				<Button
					size="small"
					text={T.SIGN_IN}
					onClick={handleLogin}
				/>
			</ShowElement>
			<LanguageDropDown/>
			<AvatarMenuWrapper Component={AvatarMenu}/>
		</div>
	);
};

export default observer(RightContent);