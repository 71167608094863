import { observer } from 'mobx-react-lite';
import authStore from 'stateManagement/stores/authStore';

const SidebarWrapper = ({ children }) => {
	const { isAuth } = authStore;

	if(isAuth) {
		return children;
	}

	return null;

};

export default observer(SidebarWrapper);