import { Layout, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import ChatDropDown from '../components/chatDropDown';
import AvatarMenu from 'view/components/global/avatarMenu';
import AvatarMenuWrapper from 'view/components/global/avatarMenuWrapper';
import LanguageDropDown from 'view/components/landingPage/languageDropDown';
import './index.scss';

const { Header } = Layout;

const DashboardHeader = () => {
	return (
		<Header className="is-auth-header">
			<div className="header-main-content">
				<div id="header-title-container" className="page-title-content"/>
				<Space size="large">
					<LanguageDropDown/>
					<ChatDropDown/>
					<AvatarMenuWrapper Component={AvatarMenu}/>
				</Space>
			</div>
		</Header>
	);
};

export default observer(DashboardHeader);