import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { ROUTE_CONSTANTS } from 'routing/router';
import authStore from 'stateManagement/stores/authStore';
import userStore from 'stateManagement/stores/usersStore';
import { isActiveUser, isPendingProfileInstructor } from 'core/helpers/profileRouteValidation';

const AvatarMenuWrapper = ({ Component }) => {
	const { isAuth } = authStore;
	const { pathname } = useLocation();
	const { meData: { data: { firstName, lastName, imagePath, email, ...restData } } } = userStore;
	const isHome = pathname === ROUTE_CONSTANTS.EMPTY;
	const isProfile = isPendingProfileInstructor(restData);
	const isActive = isActiveUser(restData);
	if(!isAuth) return null;

	return (
		<Component
			email={email}
			isHome={isHome}
			isActive={isActive}
			isProfile={isProfile}
			imagePath={imagePath}
			fullName={`${firstName} ${lastName}`}
		/>
	);
};
export default observer(AvatarMenuWrapper);