import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { TranslationContext } from 'context';
import { useHistory } from 'react-router-dom';
import Loading from 'view/components/global/loading';
import { ROUTE_CONSTANTS } from '../../routing/router';
import authStore from 'stateManagement/stores/authStore';
import { LanguageContext } from '../../context/language';
import userStore from 'stateManagement/stores/usersStore';
import { userStatus, userTypes } from 'core/constants/enums';
import languagesStore from 'stateManagement/stores/languagesStore';
import currenciesStore from 'stateManagement/stores/currenciesStore';
import AppLayoutWrapper from 'view/components/global/appLayoutWrapper';
import { hubConnection } from 'core/services/signalrService/createHubConnection';

const App = () => {
	const history = useHistory();
	const { language } = useContext(LanguageContext);
	const { isAuth } = authStore;
	const { meData: { data: meData, isLoading } } = userStore;
	const { languagesForProject: { data, isLoading: languagesLoading } } = languagesStore;

	useEffect(() => {
		if(isAuth) {
			currenciesStore.getCurrencies();
		}
	}, [ isAuth ]);

	useEffect(() => {
		if(language) {
			languagesStore.getLanguageTextsForProject(language.id);
		}
	}, [ language ]);

	useEffect(() => {
		if(meData.status === userStatus.PENDING_PROFILE && meData.type === userTypes.INSTRUCTOR) {
			history.push(ROUTE_CONSTANTS.PROFILE);
		}

		if(meData.status === userStatus.PENDING_REVIEW && meData.type === userTypes.INSTRUCTOR) {
			history.push(ROUTE_CONSTANTS.SUCCESS_SETUP_PROFILE);
		}

	}, [ meData ]);

	useEffect(() => {
		isAuth && createHubConnection();
	}, [ isAuth ]);

	const createHubConnection = () => {
		try {
			hubConnection.connectedAllSignalrEvent();
			hubConnection.connection.start();
		} catch (error) {
			console.log(error);
		}
	};

	if(isLoading || languagesLoading) {
		return <Loading/>;
	}

	return (
		<div>
			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1"/>
				<meta name="theme-color" content="#000000"/>
			</Helmet>
			<TranslationContext.Provider value={data}>
				<AppLayoutWrapper isAuth={isAuth}/>
			</TranslationContext.Provider>
		</div>
	);
};

export default observer(App);
