import { Spin } from 'antd';
import './index.scss';

const Loading = () => {
    return (
        <div className="main-loading-container">
            <Spin size="large"/>
        </div>
    )
};

export default Loading;