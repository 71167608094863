import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import useTranslation from 'hooks/useTranslation';
import './index.scss';

const DatePicker = ({
						name,
						label,
						showTime,
						onChange,
						readOnly,
						placeholder,
						disabledDate,
						width = '100%',
						format = 'YYYY-MM-DD',
						...restProps
					}) => {
	const translate = useTranslation();

	return (
		<AntDatePicker
			format={format}
			style={{ width }}
			showTime={showTime}
			onChange={onChange}
			readOnly={readOnly}
			disabledDate={disabledDate}
			placeholder={translate(placeholder)}
			className={`${readOnly ? 'readonly_date_picker' : ''} date_picker_container`}
			{...restProps}
		/>
	);
};

export default DatePicker;