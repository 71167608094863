import { errorNotification } from './toasterHelper';
import LocalStorageHelper from './localStorageHelper';
import { CONTENT_TYPES } from '../constants/apiConstants/contentTypes';
import { STATUS_ERROR_CODES } from '../constants/apiConstants/errorCodes';
import authStore, { ACCESS_TOKEN } from '../../stateManagement/stores/authStore';

/**
 * It takes a response object, checks if the response is ok, if not, it tries to parse the response as JSON, if it can't,
 * it throws an error, if it can, it checks if the JSON has an errorMessage property, if it does, it displays a
 * notification with the errorMessage, if it doesn't, it returns the JSON
 * @param response - The response object from the fetch request
 * @returns - If the response is ok, the response is returned
 * 	- If the response is not ok, the error message is returned
 * 	- If the response is not ok and there is no error message, the status code is returned
 */

export const errorHandler = async (response) => {
	if(response) {
		const { ok, status } = response;
		try {
			if(CONTENT_TYPES.OCTET_STREAM !== response.headers.get('content-type')) {
				const result = await response.clone().json();
				if(result.errorMessage) {
					errorNotification({
						message: result.errorMessage
					});
					return ({
						errorMessage: result.errorMessage,
						ok, status
					});
				}
				return result;
			} else {
				return response;
			}
		} catch (error) {
			if(status === STATUS_ERROR_CODES.UNAUTHORIZED) {
				window.location.reload();
				authStore.setIsAuth(false);
				LocalStorageHelper.deleteItem(ACCESS_TOKEN);
			}
			errorNotification({
				message: STATUS_ERROR_CODES[status]
			});
		}
	}
};