//---------------------- Edit Section Helpers ----------------------

//form Data Key model
import { landingPageSectionType } from '../../../core/constants/enums';

export const editSectionFormDataModel = (data) => {
	const {
		title,
		secondaryTitle,
		description,
		editorValue,
		imagePath,
		imgPosition,
		actionButtonText,
		redirectUrl,
		fileData: { name, blob }
	} = data;
	const formData = new FormData();

	console.log(data, 'data');
	formData.append('Title', title || '');
	formData.append('SecondaryTitle', secondaryTitle || '');
	formData.append('Paragraph', description || '');
	formData.append('PageContent', editorValue);
	if(imagePath && !blob) {
		formData.append('Image.FileUrl', imagePath);
	}

	if(blob) {
		formData.append('Image.File', blob, name);
	}

	formData.append('Image.Position', imgPosition);
	formData.append('ActionButton.Text', actionButtonText);
	formData.append('ActionButton.RedirectUrl', redirectUrl);
	return formData;
};

export const dynamicPageContentInitialState={	
	name:'',
	path:'',
	content:''
}

export const sectionContentInitialState = {
	title: '',
	secondaryTitle: '',
	redirectUrl: '',
	pageContent: '',
	order: 1,
	isMain: null,
	id: '',
	description: '',
	actionButtonText: '',
	image: {
		position: '', url: ''
	}
};

export const sectionDataModel = (data) => (data.map(item => {
	return ({
		...item,
		name: landingPageSectionType[item.type] || ''
	});
}));


//-------------------------------------------------------------------------


//---------------------- Proposals (Banners) Helpers ----------------------

export const createAddProposalsFormDataModel = (data) => {
	const { imageData: { blob, name }, imageUrl, ...restData } = data;
	const formData = new FormData();
	Object.entries(restData).forEach(([ key, value ]) => {
		formData.append(`${key}`, value);
	});

	if(blob) {
		formData.append(`Image`, blob, name);
	} else {
		formData.append(`imageUrl`, imageUrl);
	}

	return formData;
};

//--------------------------------------------------------------------------