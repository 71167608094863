import StoreBase from '../storeBase';
import { action, makeObservable, observable } from 'mobx';
import feedbacksApiHandler from '../../core/services/apiService/apiHandlers/feedbacksApiHandler';

class FeedbacksStore extends StoreBase {
	courseFeedbacks = this.toState({
		filteredCount: 0,
		items: []
	});

	instructorFeedbacks = this.toState({
		filteredCount: 0,
		items: []
	});

	constructor() {
		super();
		makeObservable(this, {
			courseFeedbacks: observable,
			instructorFeedbacks: observable,
			setCourseFeedbacks: action,
			setInstructorFeedbacks: action
		});
	}

	setInstructorFeedbacks(instructorFeedbacks) {
		this.instructorFeedbacks = instructorFeedbacks;
	}

	setCourseFeedbacks(courseFeedbacks) {
		this.courseFeedbacks = courseFeedbacks;
	}

	async getCourseFeedbacks(filter) {
		this.setCourseFeedbacks(this.toState(this.courseFeedbacks.data, true));
		const res = await feedbacksApiHandler.getCourseFeedbacks(filter);
		const result = res?.result ? this.toState(res.result) : { ...this.courseFeedbacks, isLoading: false };
		this.setCourseFeedbacks(result);
	}

	async getInstructorFeedbacks(data) {
		this.setInstructorFeedbacks(this.toState(this.instructorFeedbacks.data, true));
		const res = await feedbacksApiHandler.getInstructorFeedbacks(data);
		const result = res?.result ? this.toState(res.result) : { ...this.instructorFeedbacks, isLoading: false };
		this.setInstructorFeedbacks(result);
	}

	async addFeedbackForInstructor(data) {
		const res = await feedbacksApiHandler.addFeedbackForInstructor(data);
		return this.isOk(res);
	}

	async addFeedbackForCourse(data) {
		const res = await feedbacksApiHandler.addFeedbackForCourse(data);
		return this.isOk(res);
	}

	async addFeedback(data) {
		const res = await feedbacksApiHandler.addFeedback(data);
		return this.isOk(res);
	}

}

const feedbacksStore = new FeedbacksStore();
export default feedbacksStore;