/**
 * It takes an object and returns a string that can be used as a query string
 * @returns A function that takes an object and returns a string.
 */
export const encodeObject = obj => {
	let str = '';
	Object.entries(obj).forEach(([ key, value ]) => {
		if(value instanceof Object) {
			str += `${key}=${encodeURIComponent(JSON.stringify(value))}&`;
		} else if(value !== undefined) {
			str += `${key}=${value}&`;
		}
	});
	if(str[str.length - 1] === '&') {
		str = str.slice(0, str.length - 1);
	}
	return str;
};
