import PropTypes from 'prop-types';
import { noop } from 'core/constants/utils';
import { Dropdown as AntDropDown, Menu } from 'antd';
import './index.scss';

const DropDown = ({
					  menu,
					  disabled,
					  children,
					  onMenuClick = noop,
					  trigger = 'click'
				  }) => {
	return (
		<AntDropDown
			overlay={
				<Menu
					items={menu}
					onClick={onMenuClick}
					className="base_drop_down_menu"
				/>
			}
			disabled={disabled}
			trigger={[ trigger ]}
			placement="bottomRight"
		>
			{children}
		</AntDropDown>
	);
};

DropDown.propTypes = {
	disabled: PropTypes.func,
	onMenuClick: PropTypes.func,
	menu: PropTypes.array.isRequired,
	children: PropTypes.element.isRequired,
	trigger: PropTypes.oneOf([ 'click', 'hover' ])
};

export default DropDown;