import React from 'react';
import { Spin } from 'antd';
import './index.scss';

const LoadingWrapper = ({ loading, children }) => {
	return (
		<div className="loading_wrapper">
			<Spin tip="loading.." spinning={loading}>
				{children}
			</Spin>
		</div>
	);
};

export default LoadingWrapper;