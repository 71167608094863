import T from '../translations/translations.json';

export const NUMBER_VALIDATIONS = {
	PHONE_NUMBER_VALIDATION: {
		pattern: new RegExp(/^\+?\d{9,14}$/),
		message: T.PHONE_NUMBER_VALIDATION_MESSAGE
	},

	INTEGER_NUMBER: {
		pattern: new RegExp(/^-?[0-9]*$/),
		message: T.INVALID_VALUE
	}
};

export const USER_DETAIL_VALIDATION = {
	FIRST_NAME_MAX_VALUE: {
		max: 150,
		message: T.FIRST_NAME_INPUT_WARNING_MESSAGE
	},
	LAST_NAME_MAX_VALUE: {
		max: 150,
		message: T.LAST_NAME_INPUT_WARNING_MESSAGE
	},
	HEADLINE: {
		max: 150,
		message: T.HEADLINE_INPUT_WARNING_MESSAGE
	}
};

export const INPUT_VALIDATION = {
	PASSWORD: {
		pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
		message: T.INVALID_PASSWORD
	},
	FULL_NAME:{
		pattern: new RegExp(/^[a-zA-Z ]{1,50}$/),
		message: T.INVALID_FULL_NAME
	}
};

export const disabledDate = d => !d || d.isAfter(new Date().toLocaleDateString().replaceAll('/', '-')) || d.isSameOrBefore("1960-01-01");

export const bioMaxSymbol = 3000;
export const firstNameMaxSymbol = 100;
export const lastNameMaxSymbol = 100;
export const educationMaxSymbol = 250;
export const interestsMaxCount = 15;
export const skillsMaxCount = 15;
export const skillsMaxSymbol = 25;
export const phoneNumberValidation = /^\+?\d{0,25}$/;
export const interestsMaxSymbol = 20;
export const materialsMax = 10;
export const materialSize = 5000000;
export const scheduleMaxDuration = 1400;
export const scheduleMinDuration = 1;
export const tagsMaxSymbol = 200;
export const courseTitleMaxSymbol = 300;
export const courseDescriptionMaxSymbol = 3000;
export const lessonTitleMaxSymbol = 250;
export const chatMessageMaxSymbol = 3000;

export const urlsValidation = {
	linkedin: {
		url: 'linkedin.com',
		maxValue: 2048,
		maxValueMessage: T.LINKEDIN_MAX_VALUE,
		message: T.INVALID_LINKEDIN_URL
	}
};

export const interestsValidation = (value, translate) => {
	const maxLengths = [];
	for (let i = 0; i < value.length; i++) {
		if(value[i].length > interestsMaxSymbol) {
			maxLengths.push(value[i]);
		}
	}

	if(value) {
		if(value.length > interestsMaxCount) {
			return Promise.reject(`${translate(T.INTERESTS_MAX_COUNT_SHOULD_BE)} ${interestsMaxCount} ${T.ITEM}`);
		}

		if(maxLengths.length) {
			return Promise.reject(`${translate(T.INVALID_INTERESTS)} (${maxLengths.join()}) ${translate(T.LENGTH_SHOULD_BE)} ${interestsMaxSymbol} ${translate(T.SYMBOL)}`);
		}

		return Promise.resolve();
	}
};

export const skillsValidation = (value, translate) => {
	const maxLengths = [];
	for (let i = 0; i < value.length; i++) {
		if(value[i].label.length > skillsMaxSymbol) {
			maxLengths.push(value[i].label);
		}
	}

	if(value) {
		if(value.length > skillsMaxCount) {
			return Promise.reject(`${translate(T.SKILLS_MAX_COUNT_SHOULD_BE)} ${skillsMaxCount} ${T.ITEM}`);
		}

		if(maxLengths.length) {
			return Promise.reject(`${translate(T.INVALID_SKILLS)} (${maxLengths.join()}) ${translate(T.LENGTH_SHOULD_BE)} ${skillsMaxSymbol} ${translate(T.SYMBOL)}`);
		}
	}

	return Promise.resolve();
};

export const tagsValidations = (data, translate) => {
	const maxLengths = [];
	for (let i = 0; i < data.length; i++) {
		if(data[i]?.name?.length > tagsMaxSymbol) {
			maxLengths.push(data[i]?.name);
		}
	}

	if(data) {
		if(maxLengths.length) {
			return Promise.reject(`${translate(T.TAGS)} ${translate(T.LENGTH_SHOULD_BE)} ${tagsMaxSymbol} ${translate(T.SYMBOL)}`);
		}
	}

	return Promise.resolve();
};

export const validationErrorsInitialState = {
	personalInformation: '',
	skills: ''
};