import { useContext } from 'react';
import { TranslationContext } from '../context';


const useTranslation = (helpersData) => {
	const contextData = useContext(TranslationContext);
	const translations = helpersData ? helpersData : contextData;
	return key => Object.keys(translations).length && translations[key] ? translations[key] : key;
};

export default useTranslation;