import { Skeleton as AntSkeleton } from 'antd';
import './index.scss';

const Skeleton = () => {
	return (
		<div className="content_management_section_skeleton">
			<div className="top_skeleton">
				<AntSkeleton
					active
					paragraph={{ rows: 10 }}
				/>

				<div className="skeleton_input_container">
					<AntSkeleton.Input
						active
						className="skeleton_input"
					/>
					<AntSkeleton.Input
						active
						className="skeleton_input"
					/>
				</div>
			</div>

			<div className="footer_skeleton">
				<AntSkeleton.Input
					active
					className="skeleton_input"
				/>
				<div className="right_content">
					<AntSkeleton.Input
						active
						className="skeleton_input"
					/>
					<AntSkeleton.Input
						active
						className="skeleton_input"
					/>
				</div>
			</div>
		</div>
	);
};

export default Skeleton;