import feedbacksStore from 'stateManagement/stores/feedbacksStore';

const feedbackType = {
	courseFeedbacks: {
		state: feedbacksStore.courseFeedbacks,
		getFeedbacks: feedbacksStore.getCourseFeedbacks
	},
	instructorFeedbacks: {
		state: feedbacksStore.instructorFeedbacks,
		getFeedbacks: feedbacksStore.getInstructorFeedbacks
	}
};

const feedbackDefaultTake = 5;

export {
	feedbackType,
	feedbackDefaultTake
};