import { useMemo } from 'react';
import { Avatar, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { IMG_URL } from 'core/constants/urls';
import { useHistory } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { ROUTE_CONSTANTS } from 'routing/router';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import authStore from 'stateManagement/stores/authStore';
import userStore from 'stateManagement/stores/usersStore';
import { userStatus, userTypes } from 'core/constants/enums';
import './index.scss';

const Content = ({ email, fullName, imagePath }) => {
	const history = useHistory();
	const translate = useTranslation();
	const handleSignOut = () => authStore.signOut();
	const { meData: { data: meData } } = userStore;

	const disabledMenu = useMemo(() => {
		let disabled = false;

		const isInstructor = meData.type === userTypes.INSTRUCTOR;

		if(meData.status === userStatus.PENDING_PROFILE && isInstructor) {
			disabled = true;
		}

		if(meData.status === userStatus.PENDING_REVIEW && isInstructor) {
			disabled = true;
		}

		if(meData.status === userStatus.PENDING_ACTIVATION && isInstructor) {
			disabled = true;
		}


		return disabled;
	}, [ meData ]);

	const handleRedirectUserCabinet = () => {
		history.push({
			pathname: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.PROFILE_SETUP}`,
			search: `?tab=userDetails`
		});
	};

	const handleRedirectUserProfile = () => {
		history.push({
			pathname: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.PROFILE_SETUP}`,
			search: `?tab=profileDetails`
		});
	};

	const handleRedirectCoursePage = () => {
		history.push(`${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.COURSES}`);
	};

	const handleRedirectCourseDashboard = () => {
		history.push(`${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.DASHBOARD}`);
	};

	return (
		<div className="avatar_drop_down_content">
			<div className="user_profile_info">
				<div>
					<Avatar
						size={80}
						src={`${IMG_URL}/${imagePath}`}
						icon={<UserOutlined/>}
					/>
					<h3>{fullName}</h3>
					<span>{email}</span>
				</div>
			</div>

			<Menu className="menu_list" style={{ overflow: 'hidden' }}>
				<Menu.Item disabled={disabledMenu}
						   onClick={handleRedirectCourseDashboard}>{translate(T.DASHBOARD)}</Menu.Item>
				<Menu.Item disabled={disabledMenu}
						   onClick={handleRedirectUserProfile}>{translate(T.PROFILE)}</Menu.Item>
				<Menu.Item disabled={disabledMenu}
						   onClick={handleRedirectCoursePage}>{translate(T.MY_COURSES)}</Menu.Item>
				<Menu.Item disabled={disabledMenu}
						   onClick={handleRedirectUserCabinet}>{translate(T.ACCOUNT_SETTINGS)}</Menu.Item>
				<Menu.Item onClick={handleSignOut}>{translate(T.SIGN_OUT)}</Menu.Item>
			</Menu>
		</div>
	);
};

export default observer(Content);