export const transformChatMessageForList = (messagesData) => {
	const { data: { items, filteredCount }, ...restData } = messagesData;

	const messages = {};
	items.forEach((item) => {
		messages[item.id] = item;
	});

	return {
		data: {
			messages,
			filteredCount
		},
		...restData
	};
};

export const transformDataForChatGroups = (newMessageData) => {
	const { id, creationDate, sender: { imagePath, name, id: senderId } } = newMessageData;
	const dataModel = {
		id,
		name: null,
		creationDate,
		hasUnreadMessage: true,
		isGroup: false,
		recipient: {
			name,
			imagePath,
			id: senderId
		}
	};
	console.log(newMessageData, 'transformDataForChatGroups');

	return dataModel;
};