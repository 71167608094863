import { lazy } from 'react';
import { GLOBAL_ROUTE_CONSTANTS } from 'routing/utils';
import onlyStudentRoute from './layers/onlyStudentRoute';
import defineGuards from 'routing/defineGuards/defineGuards';

const StudentCourseDetail = lazy(() => import('view/pages/courses/student/courseDetail'));

export const ROUTE_CONSTANTS = {
	COURSE_DETAIL: '/course-detail'
};

export const studentRoutes = [
	{
		path: `${GLOBAL_ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.COURSE_DETAIL}/:courseId`,
		component: () => defineGuards([ onlyStudentRoute ], StudentCourseDetail),
		exact: true
	}
];