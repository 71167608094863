import { observable, action, makeObservable } from 'mobx';
import Fetch from '../../core/services/apiService/fetch';
import StoreBase from '../storeBase';
import { storageStateKey } from '../../core/constants/utils';
import LocalStorageHelper from '../../core/helpers/localStorageHelper';
import authApiHandler from '../../core/services/apiService/apiHandlers/authApiHandler';

export const ACCESS_TOKEN = 'token';

class AuthStore extends StoreBase {
	isAuth = false;
	isRegister = false;
	isForgetPassword = false;
	token=""

	constructor() {
		super();
		makeObservable(this, {
			isAuth: observable,
			isRegister: observable,
			isForgetPassword: observable,
			setIsRegister: action,
			setIsAuth: action,
			setIsForgetPassword: action,
			setToken:action,
			token:observable
		});
	}

	setIsAuth(isAuth) {
		this.isAuth = isAuth;
	}

	setIsRegister(isRegister) {
		this.isRegister = isRegister;
	}

	setIsForgetPassword(isForgetPassword) {
		this.isForgetPassword = isForgetPassword;
	}

	setToken(token){
		this.token=token;
	}

	async login(data) {
		const response = await authApiHandler.login(data);
		this.handleNewTokenArrival(response);
		return response;
	}

	async loginSso(token){
		this.handleSSOTokenArrival(token);
		return token;
	}

	async register(data) {
		const response = await authApiHandler.register(data);
		const isOk = this.isOk(response);
		isOk && this.setIsRegister(true);
		return isOk;
	}

	async logout() {
		await authApiHandler.logout();
	}

	async signOut() {
		const response = await authApiHandler.signOut();
		this.setIsAuth(false);
		Fetch.token = '';
		LocalStorageHelper.deleteItem(ACCESS_TOKEN);
		LocalStorageHelper.deleteItem(storageStateKey);
		window.location.reload();
		return response;
	}

	async getToken(){
		const token = LocalStorageHelper.getItem(ACCESS_TOKEN);
		this.setToken(token);
		return this.isOk( token);
	}

	async forgetPassword(data) {
		const res = await authApiHandler.forgetPassword(data);
		const isOk = this.isOk(res);
		isOk && this.setIsForgetPassword(true);
		return isOk;
	}

	async resetPassword(data) {
		const res = await authApiHandler.resetPassword(data);
		const isOk = this.isOk(res);
		return isOk;
	};

	async init() {
		const token = LocalStorageHelper.getItem(ACCESS_TOKEN);
		Fetch.token = token;
		if(token) {
			this.setIsAuth(true);
		}
	}

	handleSetNewRegisterToken(data) {
		if(data?.result) {
			const { token } = data.result;
			Fetch.token = token;
			LocalStorageHelper.deleteItem(ACCESS_TOKEN);
			LocalStorageHelper.setItem(ACCESS_TOKEN, token);
			this.setIsAuth(true);
		}

	};

	handleNewTokenArrival(data) {
		if(data?.result) {
			const { token } = data.result;
			Fetch.token = token;
			LocalStorageHelper.deleteItem(ACCESS_TOKEN);
			this.setIsAuth(true);
			this.setToken(token);
			LocalStorageHelper.setItem(ACCESS_TOKEN, token);
		}
	}

	handleSSOTokenArrival(token){
		if(token !== null || token !== undefined){			
			Fetch.token = token;
			LocalStorageHelper.deleteItem(ACCESS_TOKEN);
			this.setIsAuth(true);
			this.setToken(token);
			LocalStorageHelper.setItem(ACCESS_TOKEN, token);
		}
	}
}

const authStore = new AuthStore();
export default authStore;