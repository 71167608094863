import React from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '../router';
import userStore from '../../stateManagement/stores/usersStore';
import { isActiveUser, isPendingProfileInstructor } from 'core/helpers/profileRouteValidation';

const IsProfileLayer = ({ children }) => {
	const { meData: { data: meData } } = userStore;

	return isPendingProfileInstructor(meData) && !isActiveUser(meData) ? children :
		<Redirect to={ROUTE_CONSTANTS.EMPTY}/>;
};

export default observer(IsProfileLayer);