import { Menu } from 'antd';
import { Link } from 'react-scroll';
import Skeleton from './skeleton';
import { observer } from 'mobx-react-lite';
import { DOMAIN } from 'core/constants/urls';
import { listToTree } from 'core/helpers/listToTree';
import { CaretDownOutlined } from '@ant-design/icons';
import landingPageStore from 'stateManagement/stores/landingPageStore';
import './index.scss';

const { SubMenu } = Menu;

const Navigation = () => {
	const { landingPageData: { data: { menuItems }, isLoading } } = landingPageStore;

	const recursiveMenu = (data) => {
		return data.map(({ id, text, sectionId, isSection, redirectUrl, children = [] }) => {
			if(!children?.length) {
				return (
					<Menu.Item key={id}>
						{
							isSection ? (
								<Link
									activeClass="activeCategoryLink"
									className={`${id} navigationLinkItem`}
									to={sectionId}
									spy={true}
									smooth={true}
									duration={500}
									offset={-50}
								>
									{text}
								</Link>
							) : <a href={redirectUrl} className="navigationLinkItem"
								   target={redirectUrl?.includes(DOMAIN) ? '_self' : '_blank'}>{text}</a>
						}
					</Menu.Item>
				);
			}
			return (
				<SubMenu key={id} title={text} icon={<CaretDownOutlined/>}>
					{recursiveMenu(children)}
				</SubMenu>
			);
		});
	};

	return (
		<div className="web_menu_navigation">

			{
				isLoading ? <Skeleton count={5}/> : (
					<Menu selectedKeys="dcs" multiple mode="horizontal" inlineIndent={2999}>
						{recursiveMenu(listToTree(menuItems))}
					</Menu>
				)
			}
		</div>

	);
};

export default observer(Navigation);