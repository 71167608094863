import React from 'react';
import { Typography } from 'antd';
import { linkTypes } from './utils';
import { fontWightTypes } from '../utils';
import { Link as RouteLink } from 'react-router-dom';
import { customGenericClassNames } from '../helpers';
import './index.scss';

const { Link: TypographyLink } = Typography;

const Link = ({
				  to,
				  size = linkTypes.small,
				  className = '',
				  ellipsis = true,
				  fontWeight = fontWightTypes.regular,
				  text,
				  onClick,
				  children,
				  type,
				  underline = false,
				  ...restProps
			  }) => {
	const hrefClassName = `${className} ${customGenericClassNames(
		type,
		ellipsis
	)} linkWrapper ${size} ${fontWeight} link`;

	if(to) {
		return (
			<RouteLink to={to} className={hrefClassName} {...restProps}>
				{text || children}
			</RouteLink>
		);
	}

	// this component use only for style
	return (
		<TypographyLink underline={underline} onClick={onClick} className={hrefClassName} {...restProps}>
			{text || children}
		</TypographyLink>
	);
};

export default Link;