import { notification } from 'antd';
import { ToasterDescription, ToasterTitle } from 'view/components/shared';
import T from '../translations/translations.json';


/**
 * It takes in an object with a title, message, placement, and duration, and returns an object with a message, description,
 * placement, and duration
 */
const createNotificationContent = ({ title, message, placement= 'topRight', duration = 4.5 }) => ({
    message: <ToasterTitle title={title}/>,
    description: <ToasterDescription description={message}/>,
    placement,
    duration
});

/**
 * It creates a notification with the title ERROR and the rest of the data passed in.
 */
export const errorNotification = ({title= T.ERROR, ...restData}) => {
    notification.error(createNotificationContent({title, ...restData}))
}

/**
 * It creates a success notification with the title "ERROR" and the rest of the data passed in.
 */
export const successNotification = ({title= T.ERROR, ...restData}) => {
    notification.success(createNotificationContent({title, ...restData}))
}


