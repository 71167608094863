import React from 'react';
import 'font-awesome/css/font-awesome.min.css';

const SocialsList = ({ socialData }) => {
	return (
		<ul className="socials_list">
			{
				socialData.map(({ id, href, icon }) => (
					<li key={id}>
						<a href={href} target="_blank">
							<i className={`fa fa-${icon}`}/>
						</a>
					</li>
				))
			}
		</ul>
	);
};

export default SocialsList;