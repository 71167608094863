import Fetch from '../fetch';

const END_POINTS = {
	prefix: 'Currencies'
};

class CurrenciesApiHandler {
	async getCurrencies(filter) {
		return await Fetch.get(END_POINTS.prefix, filter);
	}
}

export default new CurrenciesApiHandler();