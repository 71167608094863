/**
 * It returns true if the userType is not in the permissions array
 * @param permissions - an array of strings that represent the permissions that are required to access the route.
 * @param userType - The user type of the user.
 * @returns A boolean value.
 */
export const hasPermission = (permissions, userType) => {
    if (permissions.length ) {
        return !permissions.includes(userType)
    }
};
