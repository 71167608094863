import React, { useMemo } from 'react';
import { Form, Input } from 'antd';
import { noop } from 'core/constants/utils';
import useTranslation from 'hooks/useTranslation';
import { Typography } from 'view/components/shared';
import T from 'core/translations/translations.json';
import { INPUT_VALIDATION } from 'core/constants/inputValidation';
import './index.scss';

const { BaseHeading } = Typography;

const PasswordInput = ({
						   max,
						   label,
						   name = 'password',
						   rules = [],
						   type = 'text',
						   placeholder,
						   onChange = noop,
						   requiredMessage,
						   value: controlledValue,
						   dependencies,
						   tooltip,
						   isCheckValidPassword,
						   hasFeedback = true,
						   isIcon = true,
						   matchPassword,
						   ...restProps
					   }) => {
	const translate = useTranslation();

	const inputProps = useMemo(() => {
		const propsData = {};
		if(!isIcon) {
			propsData.iconRender = () => null;
		}

		return propsData;
	}, [ isIcon ]);

	const rulesData = useMemo(() => {
		let data = [];

		if(rules?.length) {
			data = rules;
		}

		if(requiredMessage) {
			data.push({
				required: true,
				message: translate(requiredMessage)
			});
		}

		return data;
	}, [ rules ]);

	const labelTitle = useMemo(() => {
		const style = {};

		if(tooltip) {
			style.marginBottom = '0';
		}

		if(label) {
			return <BaseHeading level={5} style={style} fontWeight="medium">{translate(label)}</BaseHeading>;
		}

		return null;
	}, [ label ]);

	return (
		<Form.Item
			name={name}
			label={labelTitle}
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}
			dependencies={dependencies}
			tooltip={translate(tooltip)}
			rules={[
				({ getFieldValue }) => ({
					validator(_, value) {
						if(!value) {
							return Promise.reject(translate(T.PLEASE_INPUT_YOUR_PASSWORD));
						}

						if(!INPUT_VALIDATION.PASSWORD.pattern.test(value) && isCheckValidPassword) {
							return Promise.reject(translate(INPUT_VALIDATION.PASSWORD.message));
						}


						if(matchPassword && value && getFieldValue(matchPassword) !== value) {
							return Promise.reject(new Error(translate(T.NOT_MATCH_PASSWORD_WARNING_INPUT_MESSAGE)));
						}

						return Promise.resolve();
					}
				}),
				...rulesData
			]}
			hasFeedback={true}
		>
			<Input.Password
				size="large"
				className="password_input"
				placeholder={translate(placeholder)}
				{...inputProps}
			/>
		</Form.Item>
	);
};

export default PasswordInput;