import Fetch from '../fetch';

const END_POINTS = {
	news: 'news',
	items: 'items',
	filters: 'filters',
	public: 'public',
	search: 'search',
	courses: 'courses',
	sections: 'sections',
	messages: 'messages',
	prefix: 'landingPage',
	unsubscribe: 'unsubscribe',
	schedules: 'schedules',
	subscribers: 'subscribers',
	instructors: 'instructors',
	scheduleRequests: 'scheduleRequests',
	magicAction:'magic-action',
	dynamicPages:'dynamicPage',
	magicCourses:'magic-courses',
	magicSearch:'magic-search',
	skillFilter:'skillfilter',
	getContact:'getContact'
};


class LandingPageApiHandler {
	async subscribers(data) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.news}/${END_POINTS.subscribers}`, data);
	}

	async getMagicActionResponse(key) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.magicAction}`, key);
	}

	async processMagicAction(data){
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.magicAction}`, data);
	}

	async unsubscribe(data) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.news}/${END_POINTS.unsubscribe}`, data);
	}

	async getMagicActonCourses(id){
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.magicCourses}/${id}`);
	}

	async getMagicSearchClient({actionKey, email, phone}){
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.magicSearch}?magicActionKey=${actionKey}&email=${email}&phone=${phone}`);
	}

	async getLandingPageData() {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.sections}`);
	}

	async sendMessage(data) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.messages}`, data);
	}

	async scheduleRequests({ data, courseId }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.courses}/${courseId}/${END_POINTS.scheduleRequests}`, data);
	}

	async getSections(id) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.sections}/${id}`);
	}

	async getCourses(languageId) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.courses}/${languageId}`);
	}

	async getInstructors(filter) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.instructors}`, filter);
	}

	async getSkillsHavingInstructor(languageId){
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.skillFilter}?languageId=${languageId}`)
	}

	async getCoursesFilters() {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.courses}/${END_POINTS.filters}`);
	}

	async searchCourses(filter) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.courses}/${END_POINTS.search}`, filter);
	}

	async getInstructor(instructorId) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.instructors}/${instructorId}`);
	}

	async getExpertContactInfo(expertId){
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.instructors}/${expertId}/${END_POINTS.getContact}`)
	}

	async getCourseSchedules(id) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.courses}/${END_POINTS.schedules}/${id}/${END_POINTS.items}`);
	}

	async getPublicCourseDetail(courseId) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.public}/${END_POINTS.courses}/${courseId}`);
	}

	async getDynamicPageContetnt({languageId, path}){
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.dynamicPages}/${languageId}/${path}`)
	}
}

const landingPageApiHandler = new LandingPageApiHandler();
export default landingPageApiHandler;
