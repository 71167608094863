
class StoreBase {

    /**
     * @param {any} data state
     * @param {boolean} isLoading
     * @returns { data, isLoading }
     */
    toState (data = null, isLoading = false) {
        return {
            data,
            isLoading
        }
    }

    /**
     * @param {any} data state
     * @param {any} response state
     * @returns { boolean }
     */
    isOk (response) {
        return response && !response.errorMessage;
    }

    /**
     * @param {boolean} isOk
     */
    handleAlerts (isOk) {
        if (isOk) {

        }
    }
}

export default StoreBase;
