import NewsLetter from './components/newsLetter';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import { Typography } from 'view/components/shared';
import Navigation from 'view/components/global/navigation';
import LanguageDropDown from 'view/components/landingPage/languageDropDown';
import './index.scss';

const { BaseBodyText } = Typography;

const Footer = () => {
	const translate = useTranslation();
	return (
		<div className="footer_container">
			<div className="footer_main_container container_width">
				<NewsLetter/>

				<div className="divider"/>

				<div className="navigation_container">
					<Navigation/>
					<LanguageDropDown isBorder/>
				</div>
			</div>

			<div className="footer_copyright_content">
				<div>
					<BaseBodyText type="secondary" size="small">
						&copy; {translate(T.COPYRIGHT_TEXT)}
					</BaseBodyText>
				</div>
			</div>
		</div>
	);
};

export default Footer;