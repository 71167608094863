import React from 'react';
import { DatePicker } from 'antd';
import useTranslation from '../../../../hooks/useTranslation';

const { RangePicker: AntRangePicker } = DatePicker;

const RangePicker = ({
						 onChange,
						 endPlaceholder = '',
						 startPlaceholder = '',
						 ...restProps
					 }) => {
	const translate = useTranslation();

	return (
		<AntRangePicker
			onChange={onChange}
			placeholder={[
				translate(startPlaceholder),
				translate(endPlaceholder)
			]}
			className="date_picker_container"
			{...restProps}
		/>
	);
};

export default RangePicker;