import React, { useMemo } from 'react';
import { Select } from 'antd';
import FormItem from '../../shared/formItem';
import useTranslation from 'hooks/useTranslation';
import T from 'core/translations/translations.json';
import { experienceLevel } from 'core/constants/enums';
import { skillsValidation } from 'core/constants/inputValidation';

const { Option } = Select;

const SkillsSelect = ({ selectedSkills, setSelectedSkills, skills, required }) => {
	const translate = useTranslation();

	const createSkillsDataModel = data => {
		return data.map((skillName) => {
			const model = {};
			const currentSkill = skillsData.find(({ name }) => name === skillName);
			if(currentSkill) {
				model.skillId = currentSkill.id;
				model.experienceLevel = experienceLevel.INTERMEDIATE;
			} else {
				model.skillName = skillName;
				model.experienceLevel = experienceLevel.INTERMEDIATE;
			}

			return model;
		});
	};

	const skillsData = useMemo(() => {
		return Object.values(skills.reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}));
	}, [ skills ]);

	const handleChangeSkills = skillsData => setSelectedSkills(createSkillsDataModel(skillsData));

	const rulesData = useMemo(() => {
		const data = [
			{
				validator: (rule, value, callback) => {
					return skillsValidation({
						translate,
						selectedSkills,
						labelName: 'skillName'
					});
				}
			}
		];

		if(required) {
			data.push({
				required: true,
				message: translate(T.PLEASE_SELECT_AT_LEAST_ONE_SKILL)
			});
		}

		return data;
	}, [ required, selectedSkills ]);

	return (
		<FormItem
			name="skills"
			label={T.SKILLS}
			rules={rulesData}
			layout="vertical"
		>
			<Select
				mode="tags"
				style={{ width: "100%" }}
				onChange={handleChangeSkills}
				placeholder={translate(T.SKILLS)}
				className="ant_des_select mode_tags_select"
			>
				{
					skillsData.map(({ name }) => (
						<Option key={name}>
							{name}
						</Option>
					))
				}
			</Select>
		</FormItem>
	);
};

export default SkillsSelect;