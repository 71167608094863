import React from 'react';
import { Empty } from 'antd';
import useTranslation from '../../../../hooks/useTranslation';
import T from '../../../../core/translations/translations.json';
import './index.scss';

const EmptyWrapper = ({ className = '', children, height = '50vh', isEmpty, text = T.NO_DATA }) => {
	const translate = useTranslation();

	if(isEmpty) {
		return (
			<div className={`${className} empty_content_wrapper`} style={{ height }}>
				<Empty description={translate(text)}/>
			</div>
		);
	}
	return children;
};

export default EmptyWrapper;