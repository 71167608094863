import T from 'core/translations/translations.json';
import { ROUTE_CONSTANTS } from 'routing/router';
import { PERMISSION_CONSTANTS } from './permissionConstants';
import {
	AreaChartOutlined,
	BookOutlined,
	DollarCircleOutlined,
	ScheduleOutlined,
	UserOutlined,
	FundViewOutlined,
	DollarOutlined,
	WechatOutlined
} from '@ant-design/icons';

export const NAVIGATIONS = [
	{
		name: T.DASHBOARD,
		to: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.DASHBOARD}`,
		icon: <AreaChartOutlined/>,
		permissions: []
	},
	{
		name: T.PROFILE_SETUP,
		to: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.PROFILE_SETUP}`,
		icon: <UserOutlined/>,
		permissions: []
	},
	{
		name: T.COURSES,
		to: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.COURSES}`,
		icon: <BookOutlined/>,
		permissions: [ PERMISSION_CONSTANTS.VIEW_INSTRUCTOR, PERMISSION_CONSTANTS.VIEW_STUDENT ]
	},
	{
		name: T.SCHEDULES,
		to: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.SCHEDULE}`,
		icon: <ScheduleOutlined/>,
		permissions: [ PERMISSION_CONSTANTS.VIEW_INSTRUCTOR ]
	},
	{
		name: T.CHAT,
		to: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.CHAT}`,
		icon: <WechatOutlined />,
		permissions: [ PERMISSION_CONSTANTS.VIEW_INSTRUCTOR, PERMISSION_CONSTANTS.VIEW_STUDENT ]
	},
	{
		name: T.FINANCIALS,
		icon: <DollarCircleOutlined/>,
		subTo: ROUTE_CONSTANTS.REVENUES,
		permissions: [ PERMISSION_CONSTANTS.VIEW_INSTRUCTOR ],
		subMenu: [
			{
				name: T.REVENUES,
				to: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.REVENUES}`,
				icon: <FundViewOutlined />
			},
			{
				name: T.PAYMENTS,
				to: `${ROUTE_CONSTANTS.CABINET}${ROUTE_CONSTANTS.PAYMENTS}`,
				icon: <DollarOutlined />
			}
		]
	}
];
