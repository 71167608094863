import { Skeleton as AntSkeleton } from 'antd';
import './index.scss';

const Skeleton = ({ count }) => {
	return (
		<div className="web_navigation_skeleton_container">
			{
				[ ...Array(count) ].map((_, i) => (
					<div key={i}>
						<AntSkeleton.Button className="skeleton_input_item" active/>
					</div>
				))
			}
		</div>
	);
};

export default Skeleton;