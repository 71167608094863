import { useEffect, useState } from 'react';
import Cabinet from './cabinet';
import LandingPage from './landingPage';
import { ROUTE_CONSTANTS } from 'routing/router';
import { useHistory, useLocation } from 'react-router-dom';
import 'antd/dist/antd.css';

const AppLayoutWrapper = ({ isAuth }) => {
	const [ isCabinet, setIsCabinet ] = useState(false);
	const { pathname } = useLocation();
	const location = useHistory().location;

	useEffect(() => {
		if(location.pathname.startsWith(ROUTE_CONSTANTS.CABINET)) {
			setIsCabinet(true);
		} else {
			setIsCabinet(false);
		}
	}, [ pathname, location ]);

	const activeCabinet = isAuth && isCabinet;

	//@@@___this case check user in account page. Account page should be side bar component ant style should fix 100vh
	if(activeCabinet) {
		return (
			<Cabinet/>
		);
	}

	//@@@___this JSX code return landing page all component
	return (
		<LandingPage/>
	);
};

export default AppLayoutWrapper;