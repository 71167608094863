/**
 * It takes a flat array of objects and returns a nested array of objects
 * @param items - The array of items to be converted to a tree.
 * @param [id=null] - The id of the parent item.
 * @param [link=parentId] - The name of the property that links the items together.
 */
export const listToTree = (items, id = null, link = 'parentId') => {
	return items
		.filter(item => item[link] === id && item.isVisible)
		.map(item => ({ ...item,title: item.text, children: listToTree(items, item.id) }));
};
