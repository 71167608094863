const buttonTypes = [
	'primary',
	'warning',
	'danger',
	'success',
	'primaryOutline',
	'warningOutline',
	'dangerOutline',
	'successOutline'
];

const buttonSizes = [
	'small',
	'medium'
];

const radiusTypes = [
	'full_radius',
	'corner_radius'
];

export {
	buttonTypes, buttonSizes, radiusTypes
};