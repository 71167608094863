import { lazy } from 'react';
import { GLOBAL_ROUTE_CONSTANTS } from '../utils';

const LandingPage = lazy(() => import('view/pages/landing'));
const CourseDetail = lazy(() => import('view/pages/landing/routes/courseDetail'));
const BrowseCourses = lazy(() => import('view/pages/landing/routes/browseCourses'));
const OurInstructors = lazy(() => import('view/pages/landing/routes/ourInstructors'));
const InstructorDetails = lazy(() => import('view/pages/landing/routes/InstructorDetails'));
const LandingAbout = lazy(() => import('view/pages/landing/routes/contentManagementSections/about'));
const LandingContact = lazy(() => import('view/pages/landing/routes/contentManagementSections/contacts'));
const BecomeInstructor = lazy(() => import('view/pages/landing/routes/contentManagementSections/becomeInstructor'));
const LandingRequestCourse = lazy(() => import('view/pages/landing/routes/contentManagementSections/requestCourse'));
const DynamicPageViewer=lazy(()=>import('view/pages/landing/routes/dynamicPageViewer'))

const ROUTE_CONSTANTS = {
	ABOUT: '/about',
	LANDING: '/landing',
	CONTACT_US: '/contact-us',
	INSTRUCTOR: '/instructor',
	COURSE_DETAIL: '/course-detail',
	REQUEST_COURSE: '/request-course',
	BROWSE_COURSES: '/browse-courses',
	OUR_INSTRUCTORS: '/our-instructors',
	BECOME_INSTRUCTOR: '/join-platform',
	DYNAMIC_PAGE:'/dynamic-page'
};

/* Defining the routes for the landing page. */
export const publicRoutes = [
	{
		path: GLOBAL_ROUTE_CONSTANTS.EMPTY,
		component: LandingPage,
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.BROWSE_COURSES,
		component: BrowseCourses,
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.OUR_INSTRUCTORS,
		component: OurInstructors,
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.INSTRUCTOR}/:instructorId`,
		component: InstructorDetails,
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.LANDING}${ROUTE_CONSTANTS.ABOUT}`,
		component: LandingAbout,
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.LANDING}${ROUTE_CONSTANTS.CONTACT_US}`,
		component: LandingContact,
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.LANDING}${ROUTE_CONSTANTS.BECOME_INSTRUCTOR}`,
		component: BecomeInstructor,
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.LANDING}${ROUTE_CONSTANTS.REQUEST_COURSE}`,
		component: LandingRequestCourse,
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.COURSE_DETAIL}/:courseId`,
		component: CourseDetail,
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.DYNAMIC_PAGE}/:path`,
		component: DynamicPageViewer,
		exact:true
	}
];