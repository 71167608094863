import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Fetch from 'core/services/apiService/fetch';
import { IMG_URL } from 'core/constants/urls';
import { DownOutlined } from '@ant-design/icons';
import { LanguageContext } from 'context/language';
import { Button, Dropdown, Menu, Space } from 'antd';
import ImgIcon from 'view/components/shared/imgIcon';
import languagesStore from 'stateManagement/stores/languagesStore';
import './index.scss';

const LanguageDropDown = ({ isBorder }) => {
	const { languages: { data: { items: languages } } } = languagesStore;
	const { language, setLanguage } = useContext(LanguageContext);

	const handleSelectLanguage = ({ name, id, flagUrl }) => {
		setLanguage({ name, id: id, flagUrl });
		Fetch.languageId = id;
	};

	const menu = useMemo(() => {
		const { id } = language;
		return (
			<Menu selectedKeys={[ `${id}` ]} selectedKey={language.id}>
				{languages.map((item) => (
					<Menu.Item key={item.id} onClick={() => {
						handleSelectLanguage(item);
					}}>
						<Space>
							<ImgIcon width="20" src={`${IMG_URL}/${item.flagUrl}`}/>
							{item.name}
						</Space>
					</Menu.Item>
				))}
			</Menu>
		);
	}, [ languages, language ]);

	return (
		<div className={`language_drop_down_content ${isBorder ? 'active_border' : ''}`}>
			<Dropdown overlay={menu}>
				<Button>
					<Space>
						<ImgIcon width="20" src={`${IMG_URL}/${language.flagUrl}`}/>
						{language.name} <DownOutlined/>
					</Space>
				</Button>
			</Dropdown>
		</div>
	);
};

export default observer(LanguageDropDown);