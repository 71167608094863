import { observer } from 'mobx-react-lite'
import userStore from '../../stateManagement/stores/usersStore'
import {
  isActiveUser,
  isPendingReviewInstructor,
} from '../../core/helpers/profileRouteValidation'
import { Redirect } from 'react-router-dom'
import { ROUTE_CONSTANTS } from '../router'
import React from 'react'

const IsSuccessSetupProfileLayer = ({children}) => {
  const { meData: { data: meData } } = userStore;

  return isPendingReviewInstructor(meData) && !isActiveUser(meData) ? children :
    <Redirect to={ROUTE_CONSTANTS.EMPTY}/>;
};


export default observer(IsSuccessSetupProfileLayer);