import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Menu } from 'antd';
import Logo from 'view/components/shared/logo';
import useTranslation from 'hooks/useTranslation';
import devaMinLogo from 'assets/icons/devaMainLogo.svg';
import devaLogoWhite from 'assets/icons/devaLogoWith.svg';
import { useHistory, useLocation } from 'react-router-dom';
import userStore from 'stateManagement/stores/usersStore';
import { hasPermission } from 'core/helpers/hasPermission';
import { NAVIGATIONS } from 'core/constants/navigationConstants';
import './index.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

const userTypeEnum = {
	3: 'viewInstructor',
	4: 'viewStudent'
};

const Sidebar = () => {
	const history = useHistory();
	const location = useLocation();
	const translate = useTranslation();
	const [ collapsed, setCollapsed ] = useState(false);
	const { meData: { data: { type } } } = userStore;

	const toggleCollapse = () => {
		setCollapsed(!collapsed);
	};

	const selectedPathName = useMemo(() => {
		return location.pathname;
	}, [ location.pathname ]);

	const handleNavigationChange = e => {
		const { key } = e;
		console.log(key, 'key');
		history.push(key);
	};

	return (
		<>
			<Sider
				theme="left"
				collapsible
				collapsed={collapsed}
				className="main_side_bar"
				onCollapse={toggleCollapse}
			>

				<div className="collapsed_action_container">

					{
						collapsed ? (
							<Logo src={devaMinLogo} width={40}/>
						) : (
							<Logo src={devaLogoWhite} width={180}/>
						)
					}
				</div>

				<Menu
					theme="dark"
					mode="inline"
					onClick={handleNavigationChange}
					className="sidebar_menu_container"
					selectedKeys={[ selectedPathName ]}
				>
					{
						NAVIGATIONS.map(({ name, to, icon, subMenu, permissions, subTo }) => {
							if(hasPermission(permissions, userTypeEnum[type])) return null;

							if(subMenu) {
								return (
									<SubMenu className="side_bar_sub_menu" key={subTo} title={translate(name)}
											 icon={icon}>
										{
											subMenu.map(({ name, to }) => {
												return (
													<Menu.Item
														key={to}
													>
														{translate(name)}
													</Menu.Item>
												);
											})
										}
									</SubMenu>
								);
							} else {
								return (
									<Menu.Item
										key={to}
										icon={icon}
									>
										{translate(name)}
									</Menu.Item>
								);
							}

						})
					}

					<Menu.Item
					>

					</Menu.Item>
				</Menu>
			</Sider>
		</>
	);

};
export default observer(Sidebar);