import { Suspense } from 'react';
import { Layout } from 'antd';
import { DashboardHeader, Loading, RouterOutlet, Sidebar, SidebarWrapper } from 'view/components/global';

const AccountDashboard = () => {
	return (
		<Layout className="main-container" style={{ height: '100vh' }}>
			<SidebarWrapper>
				<Sidebar/>
			</SidebarWrapper>

			<div className="site-layout">
				<DashboardHeader/>
				<div>
					<Suspense fallback={<Loading/>}>
						<RouterOutlet/>
					</Suspense>
				</div>
			</div>
		</Layout>
	);
};

export default AccountDashboard;