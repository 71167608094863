import { authorFirstLetter } from './helpers';
import { Avatar, Space } from 'antd';
import { dateTimeZone } from 'core/helpers/momentTimeZone';
import { DATE_FORMATS } from 'core/constants/globalConstants';
import { Rate, RichText, Typography } from 'view/components/shared';

const { BaseHeading, BaseBodyText } = Typography;

const Item = ({ score, text, author, creationDate }) => {
	return (
		<div className="feedback_item">
			<div className="user_info">
				<div>
					<div className="info_data">
						<Avatar
							style={{
								backgroundColor: '#2728E5',
								verticalAlign: 'middle'
							}}
							size={60}
							gap={20}
						>
							{authorFirstLetter(author)}
						</Avatar>

						<div>
							<BaseHeading level={5} className="author_name">
								{author}
							</BaseHeading>

							<Space>
								<Rate value={score}/>
								<BaseBodyText
									size="small"
								>
									{dateTimeZone(creationDate, DATE_FORMATS.POINT_SHORT)}
								</BaseBodyText>
							</Space>
						</div>
					</div>

					<BaseBodyText size="small" className="feedbacks_text">{}</BaseBodyText>
					<div className="feedbacks_text">
						<RichText text={text}/>
					</div>
				</div>

			</div>
		</div>
	);
};

export default Item;