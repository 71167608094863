/**
 * If the browser supports the clipboard API, use it, otherwise fall back to the old execCommand method
 * @param text - The text to copy to the clipboard.
 * @returns A promise that resolves to undefined.
 */
export async function copyTextToClipboard(text) {
	if('clipboard' in navigator) {
		return await navigator.clipboard.writeText(text);
	} else {
		return document.execCommand('copy', true, text);
	}
}