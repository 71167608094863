import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { userStatus } from '../../core/constants/enums';
import userStore from '../../stateManagement/stores/usersStore';
import { ROUTE_CONSTANTS } from '../router';

const IsDeclinedProfileLayer = ({ children }) => {
	const { meData: { data: { status } } } = userStore;
	return status === userStatus.DECLINED ? children : <Redirect to={ROUTE_CONSTANTS.EMPTY}/>;
};

export default observer(IsDeclinedProfileLayer);
;