import React, { useContext } from 'react';
import { Form } from 'antd';
import { socialData } from '../../utils';
import SocialsList from '../../socialsList';
import { ROUTE_CONSTANTS } from 'routing/router';
import { arrowRight } from 'core/constants/icons';
import useTranslation from 'hooks/useTranslation';
import { LanguageContext } from 'context/language';
import T from 'core/translations/translations.json';
import { Logo, Typography } from 'view/components/shared';
import devaBlackLogo from 'assets/icons/devaBlackLogo.svg';
import { successNotification } from 'core/helpers/toasterHelper';
import landingPageStore from 'stateManagement/stores/landingPageStore';
import ButtonInsideInput from 'view/components/landingPage/buttonInsideInput';

const { BaseHeading, BaseBodyText } = Typography;

const NewsLetter = () => {
	const [ form ] = Form.useForm();
	const translate = useTranslation();
	const { language: { id: languageId } } = useContext(LanguageContext);

	const handleSubscribers = (value) => {
		const { email } = value;
		landingPageStore.subscribers({ email, languageId })
			.then(resp => {
				if(resp) {
					form.resetFields();
					successNotification({
						title: T.SUBSCRIBERS_SUCCESS
					});
				}
			});
	};

	return (
		<div className="news_letter">
			<div className="col_left">
				<Logo href={ROUTE_CONSTANTS.EMPTY} src={devaBlackLogo}/>
				<BaseBodyText
					className="footer_text"
					type="secondary" size="small"
				>
					{translate(T.FOOTER_TEXT)}
				</BaseBodyText>
				<SocialsList
					socialData={socialData}
				/>
			</div>

			<Form
				form={form}
				className="col_right"
				onFinish={handleSubscribers}
			>
				<div className="right_bar">
					<BaseHeading hideMargin level={3}>{translate(T.NEWSLETTER)}</BaseHeading>
					<BaseBodyText className="footer_text" type="secondary" size="small">
						{translate(T.SUBSCRIBE_FOOTER_TEXT)}
					</BaseBodyText>
					<ButtonInsideInput
						type="email"
						name="email"
						icon={arrowRight}
						placeholder={T.EMAIL_ADDRESS}
					/>
				</div>
			</Form>
		</div>
	);
};


export default NewsLetter;