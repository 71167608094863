import { Link } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '../../../../routing/router'

const Logo = ({
                  src,
                  width = '200'
              }) => {
    return (
        <Link to={ROUTE_CONSTANTS.EMPTY}>
            <img src={src} alt="" width={width}/>
        </Link>
    );
};

export default Logo;