import React from 'react';
import { Tooltip } from 'antd';
import useTranslation from 'hooks/useTranslation';
import './index.scss';

const ImgIcon = ({ placement = 'bottom', onClick, src, title, width = '25', disabled }) => {
	const translate = useTranslation();

	return (
		<Tooltip
			onClick={onClick}
			placement={placement}
			title={translate(title)}
			className={`icon-img-content ${disabled ? 'disabled' : ''}`}
		>
			<img
				src={src}
				alt={translate(title)}
				width={width}
			/>
		</Tooltip>
	);
};

export default ImgIcon;