import React from 'react';
import { Typography } from 'antd';
import { bodyTextTypes } from './utils';
import { fontWightTypes } from '../utils';
import './index.scss';

const { Text } = Typography;

/**
 * It's a function that returns a Text component with some props
 * @returns A Text component with the className prop set to the className passed in, the customGenericClassNames function,
 * the bodyTextWrapper class, the size class, and the fontWeight class.
 */
const BodyText = ({
					  text,
					  className = '',
					  size = bodyTextTypes.extraSmall,
					  fontWeight = fontWightTypes.regular,
					  children,
					  ...restProps
				  }) => {
	return (
		<Text
			className={`${className} bodyTextWrapper ${size} ${fontWeight}`}
			{...restProps}
		>
			{text || children}
		</Text>
	);
};

export default BodyText;