import React, { useContext, useEffect } from 'react';
import Navigation from '../../navigation';
import Logo from 'view/components/shared/logo';
import { ROUTE_CONSTANTS } from 'routing/router';
import { LanguageContext } from 'context/language';
import RightContent from '../components/rightContent';
import { useWindowScroll } from 'hooks/useWindowScroll';
import devaBlackLogo from 'assets/icons/devaBlackLogo.svg';
import landingPageStore from 'stateManagement/stores/landingPageStore';
import './index.scss';

const LandingHeader = () => {
	const { ref: headerRef } = useWindowScroll();
	const { language } = useContext(LanguageContext);

	useEffect(() => {
		landingPageStore.getLandingPageData();
	}, [ language ]);

	return (
		<div className="landing_page_header_container" ref={headerRef}>
			<div className="landing_page_header_main_content">
				<div className="logo_container">
					<Logo href={ROUTE_CONSTANTS.EMPTY} src={devaBlackLogo}/>
				</div>

				{/* <div className="navigation_right_container"> */}
					<div className="navigation">
						<Navigation/>
					</div>
					<div className="right_content">
						<RightContent/>
					</div>
				{/* </div> */}
			</div>
		</div>
	);
};

export default LandingHeader;