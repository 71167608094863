import { INCLUDE_CREDENTIALS } from '../globalConstants';
import API_VERSIONS from './apiVersions';
import { CONTENT_TYPES } from './contentTypes';

export const DEFAULT_OPTIONS = {
    contentType: CONTENT_TYPES.APPLICATION_JSON,
    credentials: INCLUDE_CREDENTIALS,
    version: API_VERSIONS.V1,
    signal: null,
    data: null
};